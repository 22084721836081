import React, { useState, useEffect } from "react";
import NameBox from "./common/nameBox";
import { BsCheckLg } from "react-icons/bs";
import { IconContext } from "react-icons";
import { HiOutlineInformationCircle } from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";

const ChatHeader = ({
  chatInfo,
  chatUsers,
  onShowUsersModal,
  assignTo,
  actionsList,
  onAssignUserClick,
  onClickUpdateStatusChat,
}) => {
  const [state, setState] = useState({
    searchQuery: "",
    // dropdownCurrent: "Open",
  });
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (chatInfo.name) setDisabled(false);
    else setDisabled(true);
  }, [chatInfo]);

  const handleChatFilterClick = ({ target }) => {
    const chatFilter = { ...state.chatFilter };
    chatFilter.selected = target.innerText;
    setState({ chatFilter });
  };
  return (
    <div className="d-flex flex-column panel-background-color bottom-border-styling chat-header-height justify-content-start">
      <div className="d-flex flex-row  justify-content-between">
        <div className="pt-2 ps-2">
          {!disabled ? (
            <NameBox name={chatInfo.name} />
          ) : (
            <div className="d-flex fw-bold align-items-center">
              <IconContext.Provider
                value={{
                  className: "shared-class font-color-primary me-1",
                  size: 25,
                }}
              >
                <HiOutlineInformationCircle />
              </IconContext.Provider>
              Select a Chat
            </div>
          )}
        </div>
        <div className="d-inline-flex flex-row justify-content-end flex-grow-1 pt-2 pe-2">
          {/* <div>Assign to dropdown</div> */}
          <Dropdown>
            <Dropdown.Toggle
              variant="light"
              id="dropdown-basic"
              // size="sm"
              className="fw-bold border-color-medium-grey rounded-pill me-4"
              disabled={disabled}
            >
              {assignTo.title}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {assignTo.items.map((item) => (
                <Dropdown.Item
                  key={item.id}
                  // href="#/action-1"
                  // onClick={handleChatFilterClick}
                  onClick={() => onAssignUserClick(item)}
                >
                  {item.first_name + " " + item.last_name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle
              variant="light"
              id="dropdown-basic"
              // size="sm"
              className="fw-bold border-color-medium-grey rounded-pill me-4"
              disabled={disabled}
            >
              {actionsList.title}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {actionsList.items.map((item) => (
                <Dropdown.Item
                  key={item.action}
                  // href="#/action-1"
                  onClick={item.onClick}
                >
                  {item.action}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <button
            className="btn btn-primary rounded-pill fw-bold"
            disabled={disabled}
            onClick={() => onClickUpdateStatusChat("closed")}
          >
            <IconContext.Provider
              value={{
                className: "shared-class font-color-white me-2",
                size: 13,
              }}
            >
              <BsCheckLg />
            </IconContext.Provider>
            Close
          </button>
        </div>
      </div>
      <div className="d-flex flex-row ps-1">
        <div
          className="d-flex flex-row justify-content-start align-items-start hover-grey clickable"
          onClick={onShowUsersModal}
        >
          {chatUsers.map((user) => (
            <span
              key={user.first_name + user.last_name}
              className="chat-users-text-styling px-1"
            >
              {user.first_name + " " + user.last_name}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
