import React, { Component } from "react";

class ProfileManagement extends Component {
  state = {};
  render() {
    return <h1>Profile Management</h1>;
  }
}

export default ProfileManagement;
