import React from "react";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";

const SettingsMenuPanelElement = ({
  iconElement,
  elementTitle,
  elementDescription,
  elementLink,
  selected,
}) => {
  let styleClasses =
    "d-flex flex-row flex-grow-1 border-bottom border-1 px-3 py-4 ";
  if (selected === "true")
    styleClasses += "settings-menu-element-selected-styling";
  else styleClasses += "settings-menu-element-styling";
  return (
    <Link to={elementLink} style={{ textDecoration: "none" }}>
      <div className={styleClasses}>
        <div className="">
          <IconContext.Provider value={{ className: "shared-class", size: 20 }}>
            {iconElement}
          </IconContext.Provider>
        </div>
        <div className="d-flex flex-column flex-grow-1 px-3">
          <span className="fw-bold mb-1">{elementTitle}</span>
          <span className="fs-7">{elementDescription}</span>
        </div>
      </div>
    </Link>
  );
};

export default SettingsMenuPanelElement;
