import React from "react";

const SearchBox = ({ value, onChange, additionalClasses }) => {
  let classes = "form-control ";
  if (additionalClasses) classes += additionalClasses;
  return (
    <input
      value={value}
      type="text"
      name="query"
      placeholder="Search..."
      className={classes}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  );
};

export default SearchBox;
