import React, { useState, useEffect } from "react";
import _ from "lodash";
import { FaPlus } from "react-icons/fa";
import { IconContext } from "react-icons";
import SlidingPane from "react-sliding-pane";
import Modal from "react-bootstrap/Modal";
import ContentLoader, { Facebook } from "react-content-loader";
import Button from "react-bootstrap/Button";
import SideBar from "./sideBar";
import { getSidebarLayout } from "../services/menuDataService";
import ResponsiveTopMenu from "./responsiveTopMenu";
import RegisterUserForm from "./registerUserForm";
import SettingsMenuPanel from "./settingsMenuPanel";
import { getSettingsMenuLayout } from "../services/menuDataService";
import SearchBox from "./common/searchBox";
import UsersTable from "./usersTable";
import "react-sliding-pane/dist/react-sliding-pane.css";
import {
  getAllUsers,
  removeUser,
  suspendUser,
  unSuspendUser,
} from "../services/userService";
import { toast } from "react-toastify";
import EditUserForm from "./editUserForm";

let allUsers = [];
const SettingsUsers = () => {
  // const MyLoader = () => (
  //   <ContentLoader>
  //     <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
  //     <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
  //     <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
  //   </ContentLoader>
  // );
  const MyLoader = (props) => (
    <ContentLoader
      speed={1}
      width={100}
      height={30}
      viewBox="0 0 100 30"
      backgroundColor="#f3f3f3"
      foregroundColor="#d9d9d9"
      {...props}
    >
      <rect x="11" y="5" rx="3" ry="3" width="77" height="5" />
      <rect x="12" y="23" rx="3" ry="3" width="52" height="6" />
    </ContentLoader>
  );
  const [sideBarToggled, setSideBarToggled] = useState(false);
  // sortColumn: { path: "name", order: "asc" },
  const [sortColumn, setSortColumn] = useState({ path: "", order: "" });
  const [state, setState] = useState({
    isRegisterUserPaneOpen: false,
    isEditUserPaneOpen: false,
  });
  const [searchQuery, setSearchQuery] = useState("");

  // const [users, setUsers] = useState([
  //   { id: 1, name: "Loading...", email: "Loading...", role: "Loading..." },
  // ]);

  const [users, setUsers] = useState([
    {
      id: 1,
      name: MyLoader(),
      email: MyLoader(),
      role: MyLoader(),
      status: MyLoader(),
    },
  ]);
  const [userEmailToRemove, setUserEmailToRemove] = useState();
  const [userToEdit, setUserToEdit] = useState();

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  //   const [users, setUsers] = useState({});
  const getPagedData = (searchQuery, sortColumn) => {
    // const {
    //   pageSize,
    //   currentPage,
    //   sortColumn,
    //   users: allMovies,
    //   searchQuery,
    //   selectedGenre,
    // } = this.state;
    // console.log("searchQuery: ", searchQuery);
    let filtered = allUsers;
    if (searchQuery) {
      filtered = allUsers.filter((m) => {
        // m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
        const searchRegex = RegExp(searchQuery.toLowerCase(), "i");
        console.log(searchRegex);
        return (
          m.name.toLowerCase().match(searchRegex) ||
          m.email.toLowerCase().match(searchRegex)
        );
      });
    }

    const { length: filteredCount } = filtered;

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    // const movies = paginate(sorted, currentPage, pageSize);

    return { totalCount: filteredCount, data: sorted };
  };

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn);
    const pagedData = getPagedData(searchQuery, sortColumn);
    setUsers(pagedData.data);
  };

  const handleSearch = (query) => {
    // console.log("Search");
    // this.setState({ searchQuery: query, selectedGenre: null, currentPage: 1 });
    setSearchQuery(query);
    // console.log("query: ", query);
    // console.log("SearchQ: ", searchQuery);
    const pagedData = getPagedData(query, sortColumn);
    setUsers(pagedData.data);
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const { data: usersList } = await getAllUsers();
        console.log("Userlist", usersList);
        allUsers = usersList;
        setUsers(usersList);
        // return users;
      } catch (ex) {
        console.log(ex);
      }
    };

    getUsers();
  }, []);

  const handleaddNewUsertoUserList = (newUser) => {
    const newUserDataMapped = {
      id: newUser.id,
      name: newUser.firstname + " " + newUser.lastname,
      email: newUser.email,
      status: "Active",
      role: newUser.role,
      firstname: newUser.firstname,
      lastname: newUser.lastname,
      phonenumber: newUser.phonenumber,
    };
    const userList = [...users, newUserDataMapped];
    setUsers(userList);
    allUsers.push(newUserDataMapped);
  };

  const handleRemoveUser = (email) => {
    setUserEmailToRemove(email);
    handleShowModal();
  };

  const handleRemoveUserFromDB = async () => {
    const email = userEmailToRemove;
    handleCloseModal();
    try {
      const { data: result } = await removeUser(email);
      if (result.numUsersAffected >= 1) {
        console.log(result);
        let userList = [...users];
        userList = userList.filter((user) => {
          return user.email !== email;
        });
        setUsers(userList);
        toast.info(`User ${email} has been removed.`, { autoClose: 2000 });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        toast.error("Could not delete: User is not registered", {
          autoClose: 2000,
        });
    }
  };

  const handleSuspendUser = async (email) => {
    try {
      const { data: result } = await suspendUser(email);
      if (result.numUsersAffected >= 1) {
        let userList = [...users];
        userList[
          _.findIndex(userList, (user) => {
            return user.email === email;
          })
        ].status = "Suspended";
        setUsers(userList);
        toast.warning(`User ${email} has been suspended.`, { autoClose: 2000 });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        toast.error("Could not find user: " + email, { autoClose: 2000 });
    }
  };

  const handleUnSuspendUser = async (email) => {
    try {
      const { data: result } = await unSuspendUser(email);
      if (result.numUsersAffected >= 1) {
        let userList = [...users];
        userList[
          _.findIndex(userList, (user) => {
            return user.email === email;
          })
        ].status = "Active";
        setUsers(userList);
        toast.info(`User ${email} is now active.`, { autoClose: 2000 });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        toast.error("Could not find user: " + email, { autoClose: 2000 });
    }
  };

  const handleToggleSidebar = () => {
    const tempSideBarToggled = !sideBarToggled;
    setSideBarToggled(tempSideBarToggled);
  };

  const closeSlidingPanel = (paneAttrName) => {
    // setState({ isRegisterUserPaneOpen: false });
    setState({ [paneAttrName]: false });
  };
  // const slidingPaneTitle = <h4>Register New User</h4>;

  let settingsMenuLayout = getSettingsMenuLayout();

  const openAndPopulateEditUserPane = (user) => {
    console.log(user);
    setUserToEdit(user);
    setState({ isEditUserPaneOpen: true });
  };

  const handleSaveEditedUser = () => {};
  return (
    <div className="container-fluid g-0">
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary fw-bold rounded-pill"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn btn-danger fw-bold font-color-white rounded-pill"
            onClick={handleRemoveUserFromDB}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      <SlidingPane
        className="bg-light"
        overlayClassName="some-custom-overlay-class"
        isOpen={state.isRegisterUserPaneOpen}
        title="Register New User"
        // title={slidingPaneTitle}
        subtitle="To add a new user, fill out the form and submit"
        width="70%"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setState({ isRegisterUserPaneOpen: false });
        }}
      >
        <div>
          <RegisterUserForm
            closeSlidingPanel={() =>
              closeSlidingPanel("isRegisterUserPaneOpen")
            }
            doaddNewUsertoUserList={handleaddNewUsertoUserList}
            // onCancelRegister={handleCancelRegister}
          />
        </div>
        <br />
      </SlidingPane>
      <SlidingPane
        className="bg-light"
        overlayClassName="some-custom-overlay-class"
        isOpen={state.isEditUserPaneOpen}
        title="Edit User"
        // title={slidingPaneTitle}
        subtitle="Edit user information and save."
        width="70%"
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setState({ isEditUserPaneOpen: false });
        }}
      >
        <div>
          <EditUserForm
            closeSlidingPanel={() => closeSlidingPanel("isEditUserPaneOpen")}
            doSaveEditedUser={handleSaveEditedUser}
            user={userToEdit}
            // onCancelRegister={handleCancelRegister}
          />
        </div>
        <br />
      </SlidingPane>

      <div className="row g-0">
        <SideBar
          toggled={sideBarToggled}
          onToggle={handleToggleSidebar}
          sideBarLayout={getSidebarLayout()}
        />
        <div className="col g-0 p-0">
          <ResponsiveTopMenu onToggleSidebar={handleToggleSidebar} />
          <div className="row g-0 p-0 h-100">
            <div className="col-sm-3 info-panel-styling">
              <SettingsMenuPanel
                settingsMenuLayout={settingsMenuLayout}
                selected="Users"
              />
            </div>
            <div className="col-sm-9 info-panel-styling bg-color-selected p-3">
              <div className="d-flex flex-column flex-grow-1 bg-white  rounded-5 p-4 shadow">
                <div className="d-flex flex-row align-content-center">
                  <div className="flex-grow-1 align-content-center">
                    <h4>User Management</h4>
                  </div>
                  <div className="d-flex mx-3 align-content-center ">
                    <SearchBox
                      additionalClasses="rounded-pill"
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="font-color-white">
                    <button
                      type="button"
                      className="btn btn-primary align-content-center rounded-pill mx-3 p-2"
                      onClick={() => setState({ isRegisterUserPaneOpen: true })}
                    >
                      <IconContext.Provider
                        value={{
                          className: "shared-class font-color-white",
                          size: 15,
                        }}
                      >
                        <FaPlus />
                      </IconContext.Provider>
                      <span className="fw-bold font-color-white mx-2">
                        Add User
                      </span>
                    </button>
                  </div>
                </div>
                <div className="d-flex flex-column align-content-center flex-grow-1 mt-5 mb-3">
                  <UsersTable
                    sortColumn={sortColumn}
                    onSort={handleSort}
                    users={users}
                    onRemoveUser={handleRemoveUser}
                    onSuspendUser={handleSuspendUser}
                    onUnSuspendUser={handleUnSuspendUser}
                    onClickOpenEditUserPane={openAndPopulateEditUserPane}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsUsers;
