import React from "react";
import Modal from "react-bootstrap/Modal";

const ConfirmationModal = ({
  title,
  message,
  showModal,
  onHide,
  onCancelButton,
  onProceedButton,
  cancelButtonText,
  proceedButtonText,
}) => {
  return (
    <Modal show={showModal} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-outline-secondary fw-bold rounded-pill"
          onClick={onCancelButton}
        >
          {cancelButtonText}
        </button>
        <button
          className="btn btn-danger fw-bold font-color-white rounded-pill"
          onClick={onProceedButton}
        >
          {proceedButtonText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
