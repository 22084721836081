import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";

class TableBody extends Component {
  state = {};
  renderCell = (item, column) => {
    // console.log("Key:", (column.path || column.key) + item.id);
    if (column.content) return column.content(item);
    const itemdata = _.get(item, column.path);
    if (column.processData) {
      return column.processData(itemdata);
    }
    return itemdata;
  };
  render() {
    const { data, columns } = this.props;
    return (
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            {columns.map((column) => (
              <td key={(column.path || column.key) + item.id}>
                {this.renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
