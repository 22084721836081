import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import config from "../config.json";

class NavBar extends Component {
  state = { collapseMenu: true };

  showHide = (e) => {
    e.preventDefault();
    console.log("test");
    this.setState({
      collapseMenu: !this.state.collapseMenu,
    });
  };

  "collapse navbar-collapse";

  render() {
    const { collapseMenu } = this.state;
    const { user } = this.props;
    const { urlPrefix } = config;
    return (
      <nav className="navbar navbar-expand-md bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to={urlPrefix + "/"}>
            <img
              src="https://reviewphoenix.com/Atlas/images/logo3mix2.png"
              style={{ width: "104px", height: "40px" }}
            />
          </Link>
          <button
            className="navbar-toggler"
            // className="btn btn-primary"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            // onClick={this.showHide}
          >
            <span className="navbar-toggler-icon">
              <svg
                className="fill-current h-4 w-4"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Mobile menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </span>
          </button>
          <div
            className={
              collapseMenu
                ? "collapse navbar-collapse justify-content-end"
                : "collapse navbar-collapse"
            }
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to={urlPrefix + "/help"}
                >
                  Help
                </NavLink>
              </li>

              {!user ? (
                <React.Fragment>
                  <li className="nav-item">
                    <NavLink className="nav-link" to={urlPrefix + "/login"}>
                      {/* <button className="btn btn-primary btn-sm">Login</button> */}
                      Login
                    </NavLink>
                  </li>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <li className="nav-item">
                    <NavLink className="nav-link" to={urlPrefix + "/profile"}>
                      {user.name}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link btn btn-primary"
                      to={urlPrefix + "/logout"}
                    >
                      Logout
                    </NavLink>
                  </li>
                </React.Fragment>
              )}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
