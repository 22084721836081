import React, { useState } from "react";
import SideBar from "./sideBar";
import { getSidebarLayout } from "../services/menuDataService";
import ResponsiveTopMenu from "./responsiveTopMenu";

const Files = () => {
  const [sideBarToggled, setSideBarToggled] = useState(false);

  const handleToggleSidebar = () => {
    const tempSideBarToggled = !sideBarToggled;
    setSideBarToggled(tempSideBarToggled);
  };

  return (
    <div className="container-fluid g-0">
      <div className="row g-0">
        <SideBar
          toggled={sideBarToggled}
          onToggle={handleToggleSidebar}
          sideBarLayout={getSidebarLayout()}
        />
        <div className="col g-0 p-0">
          <ResponsiveTopMenu onToggleSidebar={handleToggleSidebar} />
          <div className="row g-0 p-0 h-100">
            <div className="col-sm-12 info-panel-styling">
              <div className="d-flex flex-column p-3">
                <h3>Files</h3>
                <div className="d-flex flex-column border-bottom pb-3">
                  Add pictures, product description documents and much more to
                  easily share with customers.
                </div>
              </div>
              <div className="d-flex flex-column px-3 py-2">
                <div className="d-flex flex-column flex-grow-1 bg-white  rounded-5 p-4 shadow"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Files;
