import React from "react";

const Select = ({ name, label, error, items, ...rest }) => {
  return (
    <div className="form-group mb-3">
      <label htmlFor={name}>{label}</label>

      <select
        name={name}
        {...rest}
        className="form-select"
        aria-label="Default select example"
      >
        <option value=""></option>
        {items.map((item) => (
          <option key={item._id} value={item._id}>
            {item.name}
          </option>
        ))}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Select;
