import React, { Component } from "react";
import ChatCard from "./chatCard";
import SearchBox from "./common/searchBox";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { MdSort } from "react-icons/md";
import { IconContext } from "react-icons";

// import DropDown from "./common/dropDown";
import ChatListItemContentLoader from "./chatListItemContentLoader";

// open and closed tabs
// List of Chats
//BirdEye
// https://www.youtube.com/watch?v=eSDralBrulw&t=82s

class ChatsListPanel extends Component {
  state = {
    searchQuery: "",
    // dropdownCurrent: "Open",
  };

  handleSearch = (query) => {
    console.log("Search", query);
    this.setState({ searchQuery: query });
  };

  render() {
    const {
      chatsList,
      selectedChatInfo,
      onChatClick,
      chatFilter,
      onChatFilterClick,
      managerMode,
      onClickManagerMode,
      onChangeManagerMode,
      sortChatsOptions,
    } = this.props;
    return (
      <React.Fragment>
        <div className="d-flex flex-column ">
          <div className="p-2 chat-header-height bottom-border-styling">
            <SearchBox
              value={this.state.searchQuery}
              onChange={this.handleSearch}
            />
            <div className="d-flex flex-row align-items-center fs-5 ">
              <Form.Check
                className="fw-bold"
                type="switch"
                id="custom-switch"
                checked={managerMode}
                onClick={onClickManagerMode}
                onChange={onChangeManagerMode}
              />
              <span className="fw-bold fs-7">Manager Mode</span>
            </div>
          </div>
          <div className="bottom-border-styling p-2">
            <div className="d-flex flex-row justify-content-between ">
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  onChange={this.handleSearch}
                  size="sm"
                  className="fw-bold border-color-medium-grey"
                >
                  {chatFilter.selected}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {chatFilter.items.map((item) => (
                    <Dropdown.Item
                      key={item}
                      // href="#/action-1"
                      onClick={onChatFilterClick}
                    >
                      {item}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  onChange={this.handleSearch}
                  size="sm"
                  className="fw-bold border-color-medium-grey"
                >
                  <IconContext.Provider
                    value={{
                      className: "shared-class",
                      size: 25,
                    }}
                  >
                    <MdSort />
                  </IconContext.Provider>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {sortChatsOptions.items.map((item) => (
                    <Dropdown.Item
                      key={item.action}
                      // href="#/action-1"
                      onClick={item.onClick}
                    >
                      {item.action}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              {/* <button className="btn"></button> */}
            </div>
            <div className="pt-1 font-color-grey fs-7 fw-bold">
              {chatFilter.chatFilterMsg}
            </div>
          </div>
        </div>
        {/* <div className="d-flex flex-column top-border-styling flex-grow-1 bg-danger"> */}
        {/* <div className="p-2 bg-primary overflow-scroll"> */}
        <div className="p-2 overflow-y-styling" style={{ height: "200px" }}>
          {chatsList[0] === "Loading" ? (
            <React.Fragment>
              <ChatListItemContentLoader />
              <ChatListItemContentLoader />
              <ChatListItemContentLoader />
              <ChatListItemContentLoader />
              <ChatListItemContentLoader />
            </React.Fragment>
          ) : (
            chatsList.map((chat) => (
              <ChatCard
                chatInfo={chat}
                selected={
                  chat.chatId === selectedChatInfo.chatId ? "yes" : "no"
                }
                key={chat.chatId}
                name={chat.name}
                status={chat.status}
                endpointLogoUrl={chat.endpointLogoUrl}
                onChatClick={onChatClick}
              />
            ))
          )}
        </div>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default ChatsListPanel;
