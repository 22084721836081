import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import jwtDecode from "jwt-decode";
import io from "socket.io-client";
import logo from "./logo.svg";
import NavBar from "./components/navBar";
import NotFound from "./components/notFound";
import ProfileManagement from "./components/profileManagement";
import Help from "./components/help";
import Logout from "./components/logout";
import Login from "./components/login";
import ChatApp from "./components/chatApp";
import Settings from "./components/settings";
import Templates from "./components/templates";
import Files from "./components/files";
import Notifications from "./components/notifications";
import config from "./config.json";
import SettingsUsers from "./components/settingsUsers";
import Analytics from "./components/analytics";
import SettingsAccount from "./components/settingsAccount";
import SettingsSecurity from "./components/settingsSecurity";
import SettingsAppearance from "./components/settingsAppearance";
import SettingsNotifications from "./components/settingsNotifications";
import {
  getCurrentUserInfo,
  setupAppLoginVariables,
} from "./services/userService";
import { getJwt } from "./services/authService";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import ProductCatalog from "./components/productCatalog";
import StoreLocations from "./components/storeLocations";
import UserProfile from "./components/userProfile";

const { urlPrefix } = config;
// const socket = io.connect("http://dubaibox.selfip.net:3001");
// const socket = io.connect("http://10.0.1.2:3001");
// const socket = io.connect("http://localhost:3001");

const App = () => {
  const [user, setUser] = useState(0);
  useEffect(() => {
    const execute = async () => {
      try {
        if (getJwt()) {
          const { data: userInfo } = await getCurrentUserInfo();
          console.log("APP Page UserInfo", userInfo);
          setupAppLoginVariables(userInfo);
          setUser(userInfo.user);
          const usecheck = JSON.parse(localStorage.getItem("user"));
          console.log(usecheck.name);
        } else {
          //logout
        }
      } catch (ex) {
        //logout
      }
    };

    execute();
    //   console.log("start2");
    //   // const jwt = localStorage.getItem("token");
    //   // const userInfo = jwtDecode(jwt);

    //   // setUser(userInfo);
    //   // localStorage.setItem("name", userInfo.first_name);
    //   // console.log(userInfo);
  }, []);
  return (
    <React.Fragment>
      {/* <NavBar user={this.state.user} /> */}
      <ToastContainer />
      <Routes>
        <Route
          path={urlPrefix + "/userprofile"}
          element={<UserProfile />}
        ></Route>
        <Route path={urlPrefix + "/help"} element={<Help />}></Route>
        <Route path={urlPrefix + "/login"} element={<Login />}></Route>
        <Route path={urlPrefix + "/logout"} element={<Logout />}></Route>
        <Route
          path={urlPrefix + "/chatapp"}
          element={
            <PrivateRoute>
              <ChatApp user={user} />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={urlPrefix + "/files"}
          element={
            <PrivateRoute>
              <Files />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={urlPrefix + "/templates"}
          element={
            <PrivateRoute>
              <Templates />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={urlPrefix + "/products"}
          element={
            <PrivateRoute>
              <ProductCatalog />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={urlPrefix + "/locations"}
          element={
            <PrivateRoute>
              <StoreLocations />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={urlPrefix + "/settings"}
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={urlPrefix + "/settings/users"}
          element={
            <PrivateRoute>
              <SettingsUsers />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={urlPrefix + "/settings/account"}
          element={
            <PrivateRoute>
              <SettingsAccount />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={urlPrefix + "/settings/security"}
          element={
            <PrivateRoute>
              <SettingsSecurity />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={urlPrefix + "/settings/appearance"}
          element={
            <PrivateRoute>
              <SettingsAppearance />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={urlPrefix + "/settings/notifications"}
          element={
            <PrivateRoute>
              <SettingsNotifications />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={urlPrefix + "/notifications"}
          element={
            <PrivateRoute>
              <Notifications />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={urlPrefix + "/analytics"}
          element={
            <PrivateRoute>
              <Analytics />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path={urlPrefix + "/not-found"}
          element={
            <PrivateRoute>
              <NotFound />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={urlPrefix + "/"}
          element={
            <Navigate from={urlPrefix + "/"} to={urlPrefix + "/login"} />
          }
        ></Route>
        <Route
          path="*"
          element={<Navigate to={urlPrefix + "/not-found"} />}
        ></Route>
      </Routes>
    </React.Fragment>
  );
};

export default App;
// class App extends Component {
//   state = {};
//   render() {
//     const { urlPrefix } = config;
//     return (
//       <React.Fragment>
//         {/* <NavBar user={this.state.user} /> */}
//         <ToastContainer />
//         <Routes>
//           <Route
//             path={urlPrefix + "/profile"}
//             element={<ProfileManagement />}
//           ></Route>
//           <Route path={urlPrefix + "/help"} element={<Help />}></Route>
//           <Route path={urlPrefix + "/login"} element={<Login />}></Route>
//           <Route path={urlPrefix + "/logout"} element={<Logout />}></Route>
//           <Route path={urlPrefix + "/chatapp"} element={<ChatApp />}></Route>
//           <Route path={urlPrefix + "/files"} element={<Files />}></Route>
//           <Route path={urlPrefix + "/snippets"} element={<Snippets />}></Route>
//           <Route path={urlPrefix + "/settings"} element={<Settings />}></Route>
//           <Route path={urlPrefix + "/not-found"} element={<NotFound />}></Route>
//           <Route
//             path={urlPrefix + "/"}
//             element={
//               <Navigate from={urlPrefix + "/"} to={urlPrefix + "/chatapp"} />
//             }
//           ></Route>
//           <Route
//             path="*"
//             element={<Navigate to={urlPrefix + "/not-found"} />}
//           ></Route>
//         </Routes>
//       </React.Fragment>
//     );
//   }
// }

// export default App;
