import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
  Image,
} from "react-pro-sidebar";
import { FaUser } from "react-icons/fa";
import { IconContext } from "react-icons";
import config from "../config.json";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { getCurrentUserFromLocalStorage } from "../services/userService";

class SideBar extends Component {
  state = {
    sideBarCollapsed: true,
    sideBarToggled: true,
  };

  handleCollapseSidebar = () => {
    const sideBarCollapsed = !this.state.sideBarCollapsed;
    console.log("sideBarCollapsed: ", sideBarCollapsed);
    this.setState({ sideBarCollapsed });
  };

  render() {
    const { header, body, footer } = this.props.sideBarLayout;
    const { onToggle, toggled } = this.props;
    const { urlPrefix } = config;
    const { name } = getCurrentUserFromLocalStorage();
    return (
      <ProSidebar
        collapsed={this.state.sideBarCollapsed}
        breakPoint="md"
        width="270px"
        collapsedWidth="80px"
        toggled={toggled}
        // toggled={false}
        onToggle={onToggle}
        // style={{ padding: "0", margin: "0" }}
        style={{ paddingLeft: "0", margin: "0", height: "100vh" }}
      >
        <SidebarHeader>
          {this.state.sideBarCollapsed ? (
            <div className="d-flex justify-content-center p-2">
              <IconContext.Provider
                value={{ className: "shared-class", size: 30 }}
              >
                <BsFillArrowRightCircleFill
                  onClick={this.handleCollapseSidebar}
                />
              </IconContext.Provider>
            </div>
          ) : (
            <div className="d-flex flex-row-reverse p-2">
              <IconContext.Provider
                value={{ className: "shared-class", size: 30 }}
              >
                <BsFillArrowLeftCircleFill
                  onClick={this.handleCollapseSidebar}
                />
              </IconContext.Provider>
            </div>
          )}

          <div className="d-flex justify-content-center pb-2">
            {/* <h3>{this.state.sideBarCollapsed ? smallTitle : title}</h3> */}
            <h3>
              {this.state.sideBarCollapsed ? (
                // ? header.attributes.smallTitle
                // : header.attributes.title
                <Link to={urlPrefix + "/chatapp"}>
                  <img
                    src={header.attributes.logoIcon.url}
                    alt="Souq Media Logo"
                    width={header.attributes.logoIcon.size.width}
                    height={header.attributes.logoIcon.size.height}
                  />
                </Link>
              ) : (
                <Link to={urlPrefix + "/chatapp"}>
                  <img
                    src={header.attributes.logo.url}
                    alt="Souq Media Logo"
                    width={header.attributes.logo.size.width}
                    height={header.attributes.logo.size.height}
                  />
                </Link>
              )}
            </h3>
          </div>
          <Menu iconShape="circle">
            <MenuItem icon={<FaUser />}>
              {name}
              <Link to="/userprofile" />
            </MenuItem>
          </Menu>
          {/* <div className="text-center">Mohamed Badr</div> */}
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="circle">
            {body.menuItems.map((menuItem) => (
              <MenuItem key={menuItem.name} icon={menuItem.icon}>
                {menuItem.name}
                {menuItem.link && <Link to={menuItem.link} />}
              </MenuItem>
            ))}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="circle">
            {footer.menuItems.map((menuItem) => (
              <MenuItem key={menuItem.name} icon={menuItem.icon}>
                {menuItem.name}
                {menuItem.link && <Link to={menuItem.link} />}
              </MenuItem>
            ))}
          </Menu>
        </SidebarFooter>
      </ProSidebar>
    );
  }
}

export default SideBar;

// render() {
//     const { title, smallTitle } = this.props;
//     console.log(this.props);
//     console.log(title, smallTitle);
//     return (
//       <ProSidebar
//         collapsed={this.state.sideBarCollapsed}
//         breakPoint="md"
//         width="270px"
//         collapsedWidth="90px"
//         style={{ padding: "0", margin: "0" }}
//       >
//         <SidebarHeader>
//           {this.state.sideBarCollapsed ? (
//             <div className="d-flex justify-content-center p-2">
//               <IconContext.Provider
//                 value={{ className: "shared-class", size: 30 }}
//               >
//                 <BsFillArrowRightCircleFill
//                   onClick={this.handleToggleSidebar}
//                 />
//               </IconContext.Provider>
//             </div>
//           ) : (
//             <div className="d-flex flex-row-reverse p-2">
//               <IconContext.Provider
//                 value={{ className: "shared-class", size: 30 }}
//               >
//                 <BsFillArrowLeftCircleFill onClick={this.handleToggleSidebar} />
//               </IconContext.Provider>
//             </div>
//           )}

//           <div className="d-flex justify-content-center pb-2">
//             <h3>{this.state.sideBarCollapsed ? smallTitle : title}</h3>
//           </div>
//         </SidebarHeader>
//         <SidebarContent>
//           <Menu iconShape="circle">
//             <MenuItem icon={<AiFillDashboard />}>Dashboard</MenuItem>
//             <MenuItem icon={<TbMessage />}>All Messages</MenuItem>
//             <MenuItem icon={<TbMessages />}>My Messages</MenuItem>
//             <MenuItem icon={<RiScissorsFill />}>
//               Snippets
//               <Link to="/snippets" />
//             </MenuItem>
//             <MenuItem icon={<IoDocumentTextSharp />}>
//               Files
//               <Link to="/files" />
//             </MenuItem>
//             <MenuItem icon={<FaRobot />}>Automation</MenuItem>
//             {/* <SubMenu title="Components" icon={<FaHeart />}>
//                   <MenuItem>Component 1</MenuItem>
//                   <MenuItem>Component 2</MenuItem>
//                 </SubMenu> */}
//           </Menu>
//         </SidebarContent>
//         <SidebarFooter>
//           <Menu iconShape="circle">
//             <MenuItem
//               icon={
//                 <IconContext.Provider value={{ className: "shared-class" }}>
//                   <IoSettingsSharp />
//                 </IconContext.Provider>
//               }
//             >
//               Settings
//               <Link to="/settings" />
//             </MenuItem>
//             <MenuItem icon={<IoLogOutOutline />}>
//               Logout
//               <Link to="/logout" />
//             </MenuItem>
//           </Menu>
//         </SidebarFooter>
//       </ProSidebar>
//     );
//   }
