import httpService from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";
import { zip } from "lodash";

const apiEndpoint = config.apiUrl + "/chat";

const chatsList = [
  {
    chatId: 1,
    name: "Ahmed Badr",
    status: "Open",
    endpointLogoUrl: "/images/google-icon.png",
  },
  {
    chatId: 2,
    name: "Sam Williams",
    status: "Open",
    endpointLogoUrl: "/images/google-icon.png",
  },
  {
    chatId: 3,
    name: "neil Strauss",
    status: "Open",
    endpointLogoUrl: "/images/google-icon.png",
  },
  {
    chatId: 4,
    name: "William Smith",
    status: "Open",
    endpointLogoUrl: "/images/google-icon.png",
  },
  {
    chatId: 5,
    name: "Mark Williams",
    status: "Open",
    endpointLogoUrl: "/images/google-icon.png",
  },
  {
    chatId: 6,
    name: "Jen Smithson",
    status: "Open",
    endpointLogoUrl: "/images/google-icon.png",
  },
  {
    chatId: 7,
    name: "Kim Jenson",
    status: "Open",
    endpointLogoUrl: "/images/google-icon.png",
  },
  {
    chatId: 8,
    name: "Jay Leno",
    status: "Open",
    endpointLogoUrl: "/images/google-icon.png",
  },
  {
    chatId: 9,
    name: "Tevor Smith",
    status: "Open",
    endpointLogoUrl: "/images/google-icon.png",
  },
  {
    chatId: 10,
    name: "Smith Johnson",
    status: "Open",
    endpointLogoUrl: "/images/google-icon.png",
  },
];

const chatMessages = [
  {
    id: 1659464803306,
    chatId: 1,
    message: "hi",
    username: "Mohamed Badr",
    northSouth: "north",
    dateTime: "2022-08-02T22:26:43+04:00",
    timeStamp: 1659464803306,
    deliveryStatus: "RecievedByUser",
  },
  {
    id: 1659464803344,
    chatId: 2,
    message: "hi2",
    username: "Ahmed Badr",
    northSouth: "north",
    dateTime: "2022-08-02T22:26:43+04:00",
    timeStamp: 1659464803344,
    deliveryStatus: "RecievedByUser",
  },
  {
    id: 1659464804344,
    chatId: 1,
    message: "hi3",
    username: "Mohamed Badr",
    northSouth: "north",
    dateTime: "2022-08-02T22:26:43+04:00",
    timeStamp: 1659464804344,
    deliveryStatus: "RecievedByUser",
  },
  {
    id: 1659464805344,
    chatId: 1,
    message: "hi4",
    username: "Ahmed Badr",
    northSouth: "north",
    dateTime: "2022-08-02T22:26:43+04:00",
    timeStamp: 1659464805344,
    deliveryStatus: "RecievedByUser",
  },
  {
    id: 1659464806306,
    chatId: 1,
    message: "hi5",
    username: "Mohamed Badr",
    northSouth: "north",
    dateTime: "2022-08-02T22:26:43+04:00",
    timeStamp: 1659464806306,
    deliveryStatus: "RecievedByUser",
  },
  {
    id: 1659464807306,
    chatId: 1,
    message: "hi6",
    username: "Ahmed Badr",
    northSouth: "north",
    dateTime: "2022-08-02T22:26:43+04:00",
    timeStamp: 1659464807306,
    deliveryStatus: "RecievedByUser",
  },
];

export function getMyChatsList(status) {
  // return chatsList;
  // console.log("Statius", status);
  return httpService.get(apiEndpoint + "/chats/me/" + status.toLowerCase());
}

export function getAllAccountChatsList(status) {
  // return chatsList;
  // console.log("Status", status);
  return httpService.get(
    apiEndpoint + "/chats/account/" + status.toLowerCase()
  );
}

export function getChatMessages(chatId) {
  // return chatMessages;
  console.log(chatId);
  return httpService.get(apiEndpoint + "/messages/" + chatId);
}

export function assignChatToUser(chatId, userId) {
  return httpService.put(apiEndpoint + "/chats/assign/", { chatId, userId });
}

export function changeChatStatus(chatId, newStatus) {
  return httpService.put(apiEndpoint + "/chats/changestatus", {
    chatId,
    newStatus,
  });
}
