import React, { useEffect, useState } from "react";
import NameBox from "./common/nameBox";
import { BiUser } from "react-icons/bi";
const ChatCard = ({
  name,
  dateTime,
  status,
  endpoint,
  endpointLogoUrl,
  selected,
  onChatClick,
  chatInfo,
}) => {
  const [chatCardStyling, setChatCardStyling] = useState();
  const [statuStyling, setStatuStyling] = useState();

  useEffect(() => {
    let chatCardStyling = "chat-card-styling justify-content-around px-2 py-1";
    if (selected === "yes")
      chatCardStyling =
        "chat-card-styling-selected justify-content-around px-2 py-1 border-start border-primary border-4 border-bottom-0";
    setChatCardStyling(chatCardStyling);
  }, [selected]);

  useEffect(() => {
    let statuStyling = "chat-card-date-text-styling";
    console.log(status);
    if (status === "closed")
      statuStyling = "chat-card-date-text-styling font-color-green fw-bold";
    else if (status === "open")
      statuStyling = "chat-card-date-text-styling font-color-primary fw-bold";

    setStatuStyling(statuStyling);
  }, [status]);
  return (
    <div className={chatCardStyling} onClick={() => onChatClick(chatInfo)}>
      <div className="d-flex flex-row justify-content-between">
        <NameBox name={name} size="small" />
        <div className="d-flex align-items-center">
          <div className="chat-card-date-text-styling flex-grow-0 ">
            tuesday
          </div>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className={statuStyling}>
          {status}
          {chatInfo.assignedto_user_id ? (
            <span>
              {" - "}
              <BiUser />
              {" " +
                chatInfo.assignedto_first_name +
                " " +
                chatInfo.assignedto_last_name[0]}
            </span>
          ) : (
            <span>
              {" - "}
              <span className="fw-bold font-color-danger">{"UNASSIGNED"}</span>
            </span>
          )}
        </div>
        <div className="d-flex flex-row justify-content-between">
          {endpointLogoUrl && (
            <img
              className="chat-card-endpoint-image-styling"
              src={endpointLogoUrl}
              alt=""
            />
          )}
          <div className="chat-card-date-text-styling">{endpoint}</div>
        </div>
      </div>
    </div>
  );
};

export default ChatCard;
