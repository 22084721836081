import React from "react";
import { logout } from "../services/authService";
import config from "../config.json";

const Logout = () => {
  const { urlPrefix } = config;
  logout();
  window.location = urlPrefix + "/login";
  return null;
};

export default Logout;
