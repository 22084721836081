import React from "react";

const DropDown = ({ name, items, ...rest }) => {
  return (
    <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      ></button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        {items.map((item) => (
          <li key={item}>
            <a className="dropdown-item" href="#">
              {item}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropDown;
