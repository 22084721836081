import React, { Component } from "react";
import SideBar from "./sideBar";
import io from "socket.io-client";
// import Moment from "react-moment";
import moment from "moment";
import "moment-timezone";
import _ from "lodash";
import Accordion from "react-bootstrap/Accordion";
import Media from "react-media";
import ChatPanel from "./chatPanel";
import ChatsListPanel from "./chatsListPanel";
import { getSidebarLayout } from "../services/menuDataService";
import {
  getMyChatsList,
  getAllAccountChatsList,
  getChatMessages,
  assignChatToUser,
  changeChatStatus,
} from "../services/chatService";
import {
  getCurrentUserFromLocalStorage,
  getAssignToUserList,
} from "../services/userService";
import { getJwt } from "../services/authService";
import InformationModal from "./common/informationModal";

import config from "../config.json";
import ResponsiveTopMenu from "./responsiveTopMenu";
import ChatMessageInput from "./chatMessageInput";
import ChatHeader from "./chatHeader";
import ChatMessageArea from "./chatMessageArea";
import { ContentLoader } from "react-content-loader";
import { toast } from "react-toastify";

let socket;
// const socket = io.connect("http://dubaibox.selfip.net:3001");
// const socket = io.connect("http://10.0.1.2:3001");
// socket.on("recieve_message", (data) => {
//   console.log(data);
// });
class ChatApp extends Component {
  state = {
    updateScrollMsgs: true,
    sideBarToggled: false,
    searchTerm: "",
    newMessage: "",
    selectedChatInfo: {
      // chatId: 1,
      // name: "Ahmed Badr",
      // deliverystatus: "Open",
      // endpointLogoUrl: "/images/google-icon.png",
    },
    chatUsers: [],
    // chatsList: getChatsList(),

    chatsList: ["Loading"],
    sortChatsOptions: {
      title: "Sort",
      selected: "",
      direction: "asc",
      items: [
        {
          action: "Status",
          onClick: () => this.handleSortChatsBy("status"),
        },
        {
          action: "Chat Name",
          onClick: () => this.handleSortChatsBy("name"),
        },
        {
          action: "Last Msg Date",
          onClick: () => this.handleSortChatsBy("updated_at_utc"),
        },
        {
          action: "Assigned to",
          onClick: () => this.handleSortChatsBy("assignedto_first_name"),
        },
      ],
    },
    chatMessages: [],
    showModal: false,
    modalContent: {
      title: "",
      body: "",
    },
    chatFilter: {
      selected: "Open",
      items: ["Open", "Closed", "All"],
      chatFilterMsg: "15 messages, 12 unread",
    },
    assignTo: {
      title: "Assign to",
      selected: "",
      items: [
        { id: 1, first_name: "Mohamed", last_name: "Badr" },
        { id: 2, first_name: "Ahmed", last_name: "Badr" },
      ],
    },
    actionsList: {
      title: "Actions",
      selected: "",
      items: [
        {
          action: "Reopen",
          onClick: () => this.handleClickUpdateStatusChat("open"),
        },
        {
          action: "Unassign",
          onClick: () => this.handleClickUpdateStatusChat("open"),
        },
      ],
    },
    managerMode: false,
  };

  constructor() {
    super();
    const token = getJwt();
    socket = io.connect("http://dubaibox.selfip.net:3001", {
      auth: { token },
    });
    // socket = io.connect("http://dubaibox.selfip.net:3001", {
    //   auth: {
    //     token:
    //       "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTQsImZpcnN0X25hbWUiOiJBaG1lZCIsImxhc3RfbmFtZSI6IkJhZHIiLCJyb2xlX2lkIjoxLCJhY2NvdW50X2lkIjoxLCJlbWFpbCI6ImFiYWRyQGdtYWlsLmNvbSIsImlhdCI6MTY2MDYzMjc0OCwiZXhwIjoxNjYxMjM3NTQ4LCJhdWQiOiJodHRwczovL3d3dy5yZXZpZXdwaG9uZWl4LmNvbSIsImlzcyI6IlNvdXEgTWVkaWEiLCJzdWIiOiJhYmFkckBnbWFpbC5jb20ifQ.Gz6Fj3c8cSbA4WntkYt6If9OPdRA1eNeFoUAN4bp9xJ7UVnRulCUd8MGuev8tMtcuu4Xm6y0TVfFX5mBdtKZOhYUZOErDjR7656scliKFZD5iWVrjAKaiGZD9tFRzTGY-Ys2dfLF9Z4yVmnJq1C_OxMhY5gfH72jh9LpcxsvV30T9aZ78EdqXadDjSZ5rwFYX90ur_018gROC6ICbp7wZgJwKT253YA7x-oL5VB2iu2bb4mPfpxhb7qtRHQ3C_R2B7BAPY_QrxtfxOyDJVhdqJ4ujv8chBDszREsdJ9TOt8yP5I3rhZGhsAm5SVE7NAzsYd0tGUM7GhIE7WJRu_Xb9x9ag6E3Axs2lO7s8GdvVhY1dJpYtUyHzUpGitRpoO6fcMOnWRvNTdIeBpLe_u6F3JQUuyKIvYwBCCNXfuXsvg1Mc5fhsjtCM1Cyh1zc4PhlZseqN4B_fGHATX5UcnhsElNZRHPCtIk2OoMp1v_Fy3hxa2k37_l8agoOyJ1TG05eWe_KM1PnR7KuAFA_MMmgqHyCoXCbGU3nFsR8snesttDPm0aF1sM6TuybZ_khOYHtT_uqexdqGxHHfE7jUiKwSUrxq1RSfNMgeuqBcwKNIIh4iP_z6ZScQfe6WDqZUCOqFGFjVgcXVBNloDmsheJdW3XPr5dXBI7-2QrM3wJ8pY",
    //   },
    // });

    // socket = io.connect("http://dubaibox.selfip.net:3001");
    // console.log("FAILED AUTH1", socket);

    socket.on("connect_error", (error) => {
      console.log("FAILED AUTH", error);
      if (
        error.data.type &&
        (error.data.type == "UnauthorizedError" ||
          error.data.code == "invalid_token")
      ) {
        // redirect user to login page perhaps?
        console.log("User token has expired");
      }
    });

    socket.on("recieve_message", (data) => {
      // console.log(data);
      let chatMessages = [...this.state.chatMessages, data];
      this.setState({ chatMessages });
      this.setState({ updateScrollMsgs: true });
      data.token = token;
      socket.emit("message_recieved", data);
    });
    //****DEV: the status (Sending,  SentToServer, RecievedByUser) functionality needs work
    //What happens when there are multipul users in the chatroom
    //what happens when the senders client is not connected to the server (need to keep trying to send unsent msgs)
    //what happens when the recievers client is not connected to the server (server needs to keep trying to send unsent msgs? or when the reviever reconnects the client needs to check the DB for differences in the chat history)
    socket.on("sent_message_recieved", (data) => {
      // console.log(data);
      let chatMessages = [...this.state.chatMessages];
      let index = _.findIndex(chatMessages, (msg) => msg.id === data.id);
      console.log("INDEX1: ", index);
      console.log("dataID: ", data.id);
      if (index === -1)
        index = _.findIndex(chatMessages, (msg) => msg.id === data.temp_id);
      console.log(data);
      console.log(chatMessages);
      console.log("INDEX: ", index);
      if (index > 0) {
        chatMessages[index].deliverystatus = "RecievedByUser";
        this.setState({ chatMessages });
      }
    });
    socket.on("connected", (data) => {
      if (this.state.selectedChatInfo.chatId) {
        // if (this.state.selectedChatInfo.chatId)
        socket.emit("join_room", this.state.selectedChatInfo.chatId);
      }
    });
    socket.on("server_saved_message", (data) => {
      let chatMessages = [...this.state.chatMessages];
      const index = _.findIndex(chatMessages, (msg) => msg.id === data.id);
      // console.log("EMIT server_saved_message");
      // console.log(data);
      // console.log(chatMessages);
      chatMessages[index].deliverystatus = "SentToServer";
      this.setState({ chatMessages });
    });
    socket.on("update_chat_users", (chatUsersList) => {
      // console.log("UPDATE CHAT USERS", chatUsersList);
      //remove duplicate users, incase the same user is logged in on 2 machines
      const chatUsers = _.uniqBy(chatUsersList, "id");
      this.setState({ chatUsers });
      // let chatMessages = [...this.state.chatMessages];
      // const index = _.findIndex(chatMessages, (msg) => msg.id === data.id);
      // // console.log("EMIT server_saved_message");
      // // console.log(data);
      // // console.log(chatMessages);
      // chatMessages[index].deliverystatus = "SentToServer";
      // this.setState({ chatMessages });
    });
  }

  loadUserChatsList = async (filter, passedManagerMode = null) => {
    try {
      console.log("selectedChatInfo", this.state.selectedChatInfo);
      let selectedFilter = filter;
      if (!selectedFilter) selectedFilter = this.state.chatFilter.selected;
      let managerMode = false;

      if (passedManagerMode === null) managerMode = this.state.managerMode;
      else managerMode = passedManagerMode;
      // console.log("manager mode:", managerMode);
      // console.log("Filter:", selectedFilter);
      let chatsListTemp;
      if (managerMode) {
        const { data: chatsList } = await getAllAccountChatsList(
          selectedFilter
        );
        chatsListTemp = chatsList;
        // console.log("test:", chatMessages);
        this.setState({ chatsList });
      } else {
        const { data: chatsList } = await getMyChatsList(selectedFilter);
        chatsListTemp = chatsList;
        // console.log("test:", chatMessages);
        this.setState({ chatsList });
      }
      if (
        this.state.selectedChatInfo.chatId &&
        _.findIndex(
          chatsListTemp,
          (chat) => chat.chatId === this.state.selectedChatInfo.chatId
        ) < 0
      ) {
        const selectedChatInfo = {};
        const chatMessages = [];
        const chatUsers = [];
        this.setState({ selectedChatInfo, chatMessages, chatUsers });
      }
      // this.setState({ updateScrollMsgs: true });
      // this.updateStatusofRecievedMessages(chatMessages);
    } catch (ex) {
      console.log(ex);
    }
  };

  componentDidMount() {
    const loadInitialMessages = async (selectedChatInfo) => {
      try {
        console.log(selectedChatInfo.chatId);
        const { data: chatMessages } = await getChatMessages(
          selectedChatInfo.chatId
        );
        console.log("test:", chatMessages);
        this.setState({ selectedChatInfo, chatMessages });
        this.setState({ updateScrollMsgs: true });
        this.updateStatusofRecievedMessages(chatMessages);
      } catch (ex) {
        console.log(ex);
      }
    };

    const loadAssignToUserList = async () => {
      try {
        // console.log(selectedChatInfo.chatId);
        const { data: assignToUserList } = await getAssignToUserList();
        // console.log("test:", chatMessages);
        const assignTo = { ...this.state.assignTo };
        assignTo.items = [...assignToUserList];
        this.setState({ assignTo });
        // this.setState({ updateScrollMsgs: true });
        // this.updateStatusofRecievedMessages(chatMessages);
      } catch (ex) {
        console.log(ex);
      }
    };

    loadAssignToUserList();
    this.loadUserChatsList();
    if (
      this.state.chatsList.length > 0 &&
      typeof this.state.chatsList[0] !== "string"
    ) {
      const selectedChatInfo = this.state.chatsList[0];
      this.setState({ selectedChatInfo });
      loadInitialMessages(selectedChatInfo);
      // if (this.state.selectedChatInfo.chatId)
      socket.emit("join_room", selectedChatInfo.chatId);
    }
  }
  componentWillUnmount() {
    socket.disconnect();
  }

  handleToggleSidebar = () => {
    const sideBarToggled = !this.state.sideBarToggled;
    this.setState({ sideBarToggled });
  };
  handleSearch = () => {
    console.log("search!");
  };

  handleSendMessage = (msgInput) => {
    let { newMessage } = this.state;
    console.log("before: ", newMessage);
    newMessage = _.trim(newMessage);
    console.log("after: ", newMessage);
    const currentUser = getCurrentUserFromLocalStorage();
    if (newMessage) {
      const nowDateTime = moment();
      const msgToAdd = {
        id: nowDateTime.valueOf(),
        chatId: this.state.selectedChatInfo.chatId,
        message: newMessage,
        displayname: currentUser.name,
        senderId: currentUser.id,
        northSouth: "north",
        datetime: nowDateTime.format("YYYY-MM-DD HH:mm:ss.SSSZ"),
        timeStamp: nowDateTime.valueOf(),
        deliverystatus: "Sending",
        // dateTime: nowDateTime.toLocaleString(),
        // dateTime: "Jul 20 11:58am",
      };
      let chatMessages = [...this.state.chatMessages, msgToAdd];
      newMessage = "";
      this.setState({ newMessage, chatMessages });
      msgInput.focus();
      this.setState({ updateScrollMsgs: true });
      // chatArea.scrollTop = chatArea.scrollHeight;
      // chatArea.scroll({ top: chatArea.scrollHeight + 20, behavior: "smooth" });
      msgToAdd.token = getJwt();
      console.log(msgToAdd);
      socket.emit("send_message", msgToAdd);
    } else {
      newMessage = "";
      this.setState({ newMessage });
    }
  };

  handleMessageChange = ({ currentTarget: input }) => {
    let newMessage = this.state.newMessage;
    newMessage = input.value;
    this.setState({ newMessage });
  };

  handleSortChatsBy = (sortBy) => {
    let chatsList = [...this.state.chatsList];
    chatsList.sort((a, b) => {
      return a[sortBy] > b[sortBy] ? 1 : -1;
    });
    this.setState({ chatsList });
  };

  updateStatusofRecievedMessages = (chatMessages) => {
    const currentUser = getCurrentUserFromLocalStorage();
    const token = getJwt();
    const messagesToUpdate = chatMessages.filter((msg) => {
      let updateMessage = false;
      if (
        currentUser.id !== msg.senderId &&
        msg.deliverystatus === "SentToServer" &&
        msg.temp_id !== 0
      )
        updateMessage = true;
      return updateMessage;
    });
    console.log("messagesto Update:", messagesToUpdate);
    for (const message of messagesToUpdate) {
      // console.log("EMIT:", message);
      message.token = token;
      socket.emit("message_recieved", message);
    }
  };

  handleChatClick = async (selectedChatInfo) => {
    console.log(
      "Changing rooms, leaving room: ",
      this.state.selectedChatInfo.chatId
    );
    socket.emit("leave_room", this.state.selectedChatInfo.chatId);
    socket.emit("join_room", selectedChatInfo.chatId);
    try {
      console.log(selectedChatInfo.chatId);
      const { data: chatMessages } = await getChatMessages(
        selectedChatInfo.chatId
      );
      console.log("test:", chatMessages);
      this.setState({ selectedChatInfo, chatMessages });
      this.setState({ updateScrollMsgs: true });
      this.updateStatusofRecievedMessages(chatMessages);
    } catch (ex) {
      console.log(ex);
    }
  };
  setUpdateScrollMsgsFalse = () => {
    this.setState({ updateScrollMsgs: false });
  };
  handleShowUsersModal = () => {
    const chatUsers = [...this.state.chatUsers];
    const modalContent = {
      title: "Users in chat",
      body: (
        <div className="d-flex flex-column">
          {chatUsers.map((user) => (
            <div key={user.id} className="my-1">
              {user.first_name + " " + user.last_name}
            </div>
          ))}
        </div>
      ),
    };
    this.setState({ modalContent });
    this.handleShowModal();
  };
  handleCloseModal = () => this.setState({ showModal: false });
  handleShowModal = () => this.setState({ showModal: true });
  handleChatFilterClick = async ({ target }) => {
    if (target.innerText !== this.state.chatFilter.selected) {
      const chatFilter = { ...this.state.chatFilter };
      chatFilter.selected = target.innerText;
      const chatsList = ["Loading"];
      // const chatMessages = [];
      this.setState({ chatFilter, chatsList });
      this.loadUserChatsList(chatFilter.selected);
    }
  };

  handleOnClickManagerMode = () => {
    const managerMode = !this.state.managerMode;
    // console.log(managerMode);
    this.loadUserChatsList(null, managerMode);
    this.setState({ managerMode });
  };

  handleOnChangeManagerMode = ({ target }) => {
    // const managerMode = !this.state.managerMode;
    // console.log(target.checked);
    // this.setState(managerMode);
  };

  handleAssignUserClick = async (user) => {
    // console.log(user);
    let chatsList = [...this.state.chatsList];
    // const chatsListOld = [...this.state.chatsList];
    let selectedChatInfo = { ...this.state.selectedChatInfo };
    const {
      assignedto_user_id,
      assignedto_first_name,
      assignedto_last_name,
      chatId,
    } = selectedChatInfo;

    // const selectedChatInfoOld = { ...this.state.selectedChatInfo };
    // console.log(this.state.selectedChatInfo);
    const index = _.findIndex(chatsList, (chat) => chat.chatId === chatId);

    try {
      if (index >= 0) {
        chatsList[index].assignedto_user_id = user.id;
        chatsList[index].assignedto_first_name = user.first_name;
        chatsList[index].assignedto_last_name = user.last_name;
        selectedChatInfo.assignedto_user_id = user.id;
        selectedChatInfo.assignedto_first_name = user.first_name;
        selectedChatInfo.assignedto_last_name = user.last_name;
        this.setState({ chatsList, selectedChatInfo });
        const { data: result } = await assignChatToUser(chatId, user.id);
        console.log(result);
        if (result.changedRows < 1)
          throw new Error("Could not assign chat to user.");
      }
    } catch (ex) {
      toast.error("Could not assign chat to user", {
        autoClose: 2000,
      });
      chatsList[index].assignedto_user_id = assignedto_user_id;
      chatsList[index].assignedto_first_name = assignedto_first_name;
      chatsList[index].assignedto_last_name = assignedto_last_name;
      selectedChatInfo.assignedto_user_id = assignedto_user_id;
      selectedChatInfo.assignedto_first_name = assignedto_first_name;
      selectedChatInfo.assignedto_last_name = assignedto_last_name;
      this.setState({ chatsList, selectedChatInfo });
    }
  };
  handleClickUpdateStatusChat = async (newStatus) => {
    const selectedChatInfo = this.state.selectedChatInfo;
    if (selectedChatInfo.status && selectedChatInfo.status !== newStatus) {
      const currentStatus = selectedChatInfo.status;
      let chatsList = [...this.state.chatsList];
      const { chatId } = selectedChatInfo;

      const index = _.findIndex(chatsList, (chat) => chat.chatId === chatId);
      if (chatId && index >= 0) {
        try {
          chatsList[index].status = newStatus;
          selectedChatInfo.status = newStatus;
          this.setState({ chatsList, selectedChatInfo });
          const { data: result } = await changeChatStatus(chatId, newStatus);
          if (result.changedRows < 1)
            throw new Error(`Could not set chat status to ${newStatus}`);
        } catch (ex) {
          toast.error(`Could not set chat status to ${newStatus}`, {
            autoClose: 2000,
          });
          chatsList[index].status = currentStatus;
          selectedChatInfo.status = currentStatus;
          this.setState({ chatsList, selectedChatInfo });
        }
      }
    }
  };
  render() {
    const { logo } = config;
    const {
      chatsList,
      updateScrollMsgs,
      chatInfo,
      chatMessages,
      newMessage,
      selectedChatInfo,
      chatUsers,
      showModal,
      modalContent,
      chatFilter,
      assignTo,
      actionsList,
      managerMode,
      sortChatsOptions,
    } = this.state;
    return (
      <div className="container-fluid g-0">
        <InformationModal
          title={modalContent.title}
          body={modalContent.body}
          showModal={showModal}
          onHide={this.handleCloseModal}
          onOkButton={this.handleCloseModal}
          okButtonText="Close"
        />
        <div className="row g-0">
          <SideBar
            toggled={this.state.sideBarToggled}
            onToggle={this.handleToggleSidebar}
            sideBarLayout={getSidebarLayout()}
          />
          <div className="col g-0 p-0 vh-100">
            <Media
              queries={{
                small: "(max-width: 767px)",
                large: "(min-width: 768px)",
              }}
            >
              {(matches) => (
                <React.Fragment>
                  {matches.large && (
                    <React.Fragment>
                      <ResponsiveTopMenu
                        onToggleSidebar={this.handleToggleSidebar}
                      />
                      <div className="row g-0 p-0">
                        <div className="col-md-3 info-panel-styling chats-list-panel-styling">
                          <ChatsListPanel
                            chatsList={chatsList}
                            selectedChatInfo={selectedChatInfo}
                            onChatClick={this.handleChatClick}
                            chatFilter={chatFilter}
                            onChatFilterClick={this.handleChatFilterClick}
                            managerMode={managerMode}
                            onClickManagerMode={this.handleOnClickManagerMode}
                            onChangeManagerMode={this.handleOnChangeManagerMode}
                            sortChatsOptions={sortChatsOptions}
                          />
                        </div>
                        <div className="col-md-9 chat-panel-styling">
                          <ChatHeader
                            chatInfo={selectedChatInfo}
                            chatUsers={chatUsers}
                            onShowUsersModal={this.handleShowUsersModal}
                            assignTo={assignTo}
                            onAssignUserClick={this.handleAssignUserClick}
                            actionsList={actionsList}
                            onClickUpdateStatusChat={
                              this.handleClickUpdateStatusChat
                            }
                          />
                          <ChatMessageArea
                            chatMessages={chatMessages.filter((msg) => {
                              return msg.chatId === selectedChatInfo.chatId;
                            })}
                            updateScrollMsgs={updateScrollMsgs}
                            onUpdateScrollMsgs={this.setUpdateScrollMsgsFalse}
                          />
                          {/* <ChatPanel
                            updateScrollMsgs={updateScrollMsgs}
                            onUpdateScrollMsgs={this.setUpdateScrollMsgsFalse}
                            chatInfo={selectedChatInfo}
                            chatMessages={chatMessages}
                            newMessage={newMessage}
                            onMessageChange={this.handleMessageChange}
                            onClickSendMessage={this.handleSendMessage}
                          /> */}
                          <ChatMessageInput
                            chatInfo={selectedChatInfo}
                            newMessage={newMessage}
                            onMessageChange={this.handleMessageChange}
                            onClickSendMessage={this.handleSendMessage}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {matches.small && (
                    <React.Fragment>
                      <div className="d-flex flex-column vh-100">
                        <div className="header-styling">
                          <ResponsiveTopMenu
                            onToggleSidebar={this.handleToggleSidebar}
                          />
                          <div className="col-md-3 info-panel-styling chats-list-panel-styling">
                            <Accordion eventKey="0" flush>
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <span style={{ fontWeight: "800" }}>
                                    Chats List
                                  </span>
                                </Accordion.Header>
                                <Accordion.Body bsPrefix="px-1">
                                  <ChatsListPanel
                                    chatsList={chatsList}
                                    selectedChatInfo={selectedChatInfo}
                                    onChatClick={this.handleChatClick}
                                    chatFilter={chatFilter}
                                    onChatFilterClick={
                                      this.handleChatFilterClick
                                    }
                                    managerMode={managerMode}
                                    onClickManagerMode={
                                      this.handleOnClickManagerMode
                                    }
                                    onChangeManagerMode={
                                      this.handleOnChangeManagerMode
                                    }
                                    sortChatsOptions={sortChatsOptions}
                                  />
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          <ChatHeader
                            chatInfo={selectedChatInfo}
                            chatUsers={chatUsers}
                            onShowUsersModal={this.handleShowUsersModal}
                            assignTo={assignTo}
                            onAssignUserClick={this.handleAssignUserClick}
                            actionsList={actionsList}
                            onClickUpdateStatusChat={
                              this.handleClickUpdateStatusChat
                            }
                          />
                        </div>
                        {/* <div className="body-styling flex-grow-1"> */}
                        <ChatMessageArea
                          chatMessages={chatMessages.filter((msg) => {
                            return msg.chatId === selectedChatInfo.chatId;
                          })}
                          updateScrollMsgs={updateScrollMsgs}
                          onUpdateScrollMsgs={this.setUpdateScrollMsgsFalse}
                        />

                        {/* <div className="row g-0 p-0">
                            <div className="col-md-9 chat-panel-styling"> */}
                        {/* <ChatPanel
                            updateScrollMsgs={updateScrollMsgs}
                            onUpdateScrollMsgs={this.setUpdateScrollMsgsFalse}
                            chatInfo={selectedChatInfo}
                            chatMessages={chatMessages}
                            newMessage={newMessage}
                            onMessageChange={this.handleMessageChange}
                            onClickSendMessage={this.handleSendMessage}
                          /> */}

                        {/* </div>
                          </div> */}
                        {/* </div> */}
                        <div className="footer-styling">
                          <ChatMessageInput
                            chatInfo={selectedChatInfo}
                            newMessage={newMessage}
                            onMessageChange={this.handleMessageChange}
                            onClickSendMessage={this.handleSendMessage}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </Media>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatApp;

{
  /* <ResponsiveTopMenu onToggleSidebar={this.handleToggleSidebar} />
<div className="row g-0 p-0"> */
}
{
  /* <div className="col-md-2 info-panel-styling">
    <Media
      queries={{
        small: "(max-width: 767px)",
        large: "(min-width: 768px)",
      }}
    >
      {(matches) => (
        <React.Fragment>
          {matches.small && (
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <span style={{ fontWeight: "800" }}>Folders</span>
                </Accordion.Header>
                <Accordion.Body>
                  inbox panel which should contain a few folders to
                  orginize the chat messages
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          {matches.large && (
            <p>
              inbox panel which should contain a few folders to
              orginize the chat messages
            </p>
          )}
        </React.Fragment>
      )}
    </Media>
  </div> */
}
//   <div className="col-md-3 info-panel-styling chats-list-panel-styling">
//     <Media
//       queries={{
//         small: "(max-width: 767px)",
//         large: "(min-width: 768px)",
//       }}
//     >
//       {(matches) => (
//         <React.Fragment>
//           {matches.small && (
//             <Accordion eventKey="0" flush>
//               <Accordion.Item eventKey="0">
//                 <Accordion.Header>
//                   <span style={{ fontWeight: "800" }}>
//                     Chats List
//                   </span>
//                 </Accordion.Header>
//                 <Accordion.Body>
//                   <ChatsListPanel
//                     chatsList={chatsList}
//                     selectedChatInfo={selectedChatInfo}
//                     onChatClick={this.handleChatClick}
//                   />
//                 </Accordion.Body>
//               </Accordion.Item>
//             </Accordion>
//           )}
//           {matches.large && (
//             <ChatsListPanel
//               chatsList={chatsList}
//               selectedChatInfo={selectedChatInfo}
//               onChatClick={this.handleChatClick}
//             />
//           )}
//         </React.Fragment>
//       )}
//     </Media>
//   </div>
//   <div className="col-md-9 chat-panel-styling">
//     <ChatPanel
//       updateScrollMsgs={updateScrollMsgs}
//       onUpdateScrollMsgs={this.setUpdateScrollMsgsFalse}
//       chatInfo={selectedChatInfo}
//       chatMessages={chatMessages}
//       newMessage={newMessage}
//       onMessageChange={this.handleMessageChange}
//       onClickSendMessage={this.handleSendMessage}
//     />
//     <ChatMessageInput
//       newMessage={newMessage}
//       onMessageChange={this.handleMessageChange}
//       onClickSendMessage={this.handleSendMessage}
//     />
//   </div>
// </div>
