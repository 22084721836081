import React from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons";
import config from "../config.json";

const ResponsiveTopMenu = ({ onToggleSidebar }) => {
  const { logo } = config;
  return (
    <div className="row">
      <div className="col bg-light">
        <div className="d-flex flex-row align-items-center top-menu">
          <div
            className="btn-toggle m-1"
            onClick={onToggleSidebar}
            style={{ width: "row" }}
          >
            <span>
              <IconContext.Provider
                value={{ className: "shared-class", size: 30 }}
              >
                <FaBars />
              </IconContext.Provider>
            </span>
          </div>
          <div className="d-flex flex-row flex-grow-1 justify-content-center">
            <img
              className="m-2"
              src={logo.url}
              alt="Souq Media Logo"
              width="79"
              height="30"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveTopMenu;
