import React, { Component } from "react";
import ChatMessageArea from "./chatMessageArea";
// import TextareaAutosize from "react-textarea-autosize";
import ChatHeader from "./chatHeader";

//name and logo of name .... maybe logo of platform
//assigned to
// close button ... other actions
// https://www.youtube.com/watch?v=DoerS2TIlyc
// https://docs.respond.io/messages

class ChatPanel extends Component {
  state = {};

  render() {
    const {
      newMessage,
      chatInfo,
      chatMessages,
      onMessageChange,
      onClickSendMessage,
      updateScrollMsgs,
      onUpdateScrollMsgs,
    } = this.props;

    return (
      <React.Fragment>
        <ChatHeader chatInfo={chatInfo} />
        {/* <div className="d-flex bg-danger flex-column flex-grow-1"> */}
        <ChatMessageArea
          chatMessages={chatMessages.filter((msg) => {
            return msg.chatId === chatInfo.chatId;
          })}
          updateScrollMsgs={updateScrollMsgs}
          onUpdateScrollMsgs={onUpdateScrollMsgs}
        />

        {/* <div className="d-flex flex-column panel-background-color top-border-styling chat-input-height p-3">
          <div
            className="d-flex flex-row flex-grow-1 align-items-center"
            style={{ flexGrow: 1 }}
          >
            <div className="form-group flex-grow-1">
              <textarea
                ref={(input) => {
                  this.msgInput = input;
                }}
                name="newMessage"
                value={newMessage}
                onChange={onMessageChange}
                onKeyPress={(e) => {
                  // e.key === "Enter" &&
                  //   onClickSendMessage(this.msgInput, this.chatArea);
                  if (e.key === "Enter") {
                    e.preventDefault();
                    onClickSendMessage(this.msgInput);
                  }
                }}
                className="form-control flex-grow-1"
                placeholder="Enter Message ..."
              />
            </div>

            <div>
              <button
                className="btn btn-danger mx-2"
                onClick={() => onClickSendMessage(this.msgInput)}
              >
                <IconContext.Provider
                  value={{
                    // color: "#FF6106",
                    color: "#ffffff",
                    size: "20px",
                    className: "global-class-name",
                  }}
                >
                  <div>
                    <MdSend />
                  </div>
                </IconContext.Provider>
              </button>
            </div>
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}

export default ChatPanel;
