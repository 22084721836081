import React, { Component } from "react";

class Help extends Component {
  state = {};
  render() {
    return <h1>Help</h1>;
  }
}

export default Help;
