import React from "react";
import ContentLoader from "react-content-loader";

const ChatListItemContentLoader = () => {
  const MyLoader = (
    <ContentLoader
      speed={1}
      width={250}
      height={90}
      viewBox="0 0 250 90"
      backgroundColor="#f3f3f3"
      foregroundColor="#d9d9d9"
    >
      <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
      <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
      <rect x="118" y="48" rx="3" ry="3" width="53" height="11" />
      <rect x="4" y="49" rx="3" ry="3" width="100" height="11" />
      <rect x="1" y="23" rx="3" ry="3" width="140" height="11" />
    </ContentLoader>
  );

  return (
    <div className="chat-card-styling justify-content-around px-2 py-1">
      {MyLoader}
    </div>
  );
};

export default ChatListItemContentLoader;
