import React, { Component } from "react";
import Joi from "joi-browser";
import TaggedInput from "./taggedInput";
import Input from "./input";
import DropDown from "./dropDown";
import Select from "./select";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };
  validate = () => {
    const result = Joi.validate(this.state.data, this.schema, {
      abortEarly: false,
    });

    if (!result.error) return null;

    const errors = {};

    for (const item of result.error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  validatePropery = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    // console.log(obj, schema);
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // const u = this.username.current.value;

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit(e);
  };

  handleChange = ({ currentTarget: input }) => {
    // console.log(input);
    const errors = { ...this.state.errors };
    const errorMessage = this.validatePropery(input);
    // console.log(errorMessage);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };
  // handleChangeTaggedList = (input) => {
  //   console.log(input);
  //   const errors = { ...this.state.errors };
  //   const errorMessage = this.validatePropery(input);
  //   console.log(errorMessage);
  //   if (errorMessage) errors[input.name] = errorMessage;
  //   else delete errors[input.name];

  //   const data = { ...this.state.data };
  //   data[input.name] = input.value;
  //   this.setState({ data, errors });
  // };
  renderButton(label, additionalClasses = "btn-primary rounded-pill") {
    const classes = "btn " + additionalClasses;
    return (
      <button disabled={this.validate()} className={classes}>
        {label}
      </button>
    );
  }

  renderInput(name, label, type = "text") {
    const { data, errors } = this.state;
    return (
      <Input
        type={type}
        name={name}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  // renderTagsInput(name, label, placeHolder = "") {
  //   const { data, errors } = this.state;
  //   console.log(name, label);
  //   return (
  //     <TaggedInput
  //       // type={type}
  //       name={name}
  //       label={label}
  //       value={data[name]}
  //       onChange={this.handleChangeTaggedList}
  //       error={errors[name]}
  //       placeHolder={placeHolder}
  //     />
  //   );
  // }

  renderDropdown(name, label, items) {
    const { data, errors } = this.state;
    return (
      <DropDown
        name={name}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        items={items}
        error={errors[name]}
      />
    );
  }

  renderSelect(name, label, items) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        items={items}
        error={errors[name]}
      />
    );
  }
}

export default Form;
