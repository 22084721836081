import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { MdSend } from "react-icons/md";
import { TbTemplate } from "react-icons/tb";
import { GrAttachment } from "react-icons/gr";
import { BsThreeDotsVertical } from "react-icons/bs";

const ChatMessageInput = ({
  chatInfo,
  newMessage,
  onMessageChange,
  onClickSendMessage,
}) => {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (chatInfo.name) setDisabled(false);
    else setDisabled(true);
  }, [chatInfo]);

  let msgInput;
  return (
    <div className="d-flex flex-column panel-background-color top-border-styling chat-input-height p-3">
      <div
        className="d-flex flex-row flex-grow-1  align-items-center"
        style={{ flexGrow: 1 }}
      >
        <div className="form-group flex-grow-1">
          <textarea
            ref={(input) => {
              msgInput = input;
            }}
            name="newMessage"
            value={newMessage}
            onChange={onMessageChange}
            onKeyPress={(e) => {
              // e.key === "Enter" &&
              //   onClickSendMessage(this.msgInput, this.chatArea);
              if (e.key === "Enter") {
                e.preventDefault();
                // onClickSendMessage(this.msgInput, this.chatArea);
                onClickSendMessage(msgInput);
              }
            }}
            className="form-control flex-grow-1"
            placeholder="Enter Message ..."
            disabled={disabled}
          />
        </div>

        <div>
          <button
            className="btn btn-danger mx-2"
            // onClick={() => onClickSendMessage(this.msgInput, this.chatArea)}
            onClick={() => onClickSendMessage(msgInput)}
            disabled={disabled}
          >
            <IconContext.Provider
              value={{
                // color: "#FF6106",
                color: "#ffffff",
                size: "20px",
                className: "global-class-name",
              }}
            >
              <div>
                <MdSend />
              </div>
            </IconContext.Provider>
          </button>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-grow-1 align-items-center"
        style={{ flexGrow: 1 }}
      >
        <div className="d-flex flex-row flex-grow-1">
          <button
            className="p-2  border border-color-medium-grey clickable rounded"
            disabled={disabled}
          >
            <TbTemplate />
            <span className="mx-2 fw-bold">Templates</span>
          </button>
        </div>
        <div className="d-flex flex-row">
          <button
            className="btn p-2 mx-2 border border-color-medium-grey clickable rounded"
            disabled={disabled}
          >
            <IconContext.Provider
              value={{
                className: "shared-classs",
                size: 20,
              }}
            >
              <GrAttachment />
            </IconContext.Provider>
          </button>
          <button
            className="p-2 ms-2 me-2 border border-color-medium-grey clickable rounded"
            disabled={disabled}
          >
            <IconContext.Provider
              value={{
                className: "shared-class",
                size: 20,
              }}
            >
              <BsThreeDotsVertical />
            </IconContext.Provider>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatMessageInput;
