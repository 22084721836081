import React, { useState } from "react";
import SideBar from "./sideBar";
import { getSidebarLayout } from "../services/menuDataService";
import ResponsiveTopMenu from "./responsiveTopMenu";

const Notifications = () => {
  const [sideBarToggled, setSideBarToggled] = useState(false);

  const handleToggleSidebar = () => {
    const tempSideBarToggled = !sideBarToggled;
    setSideBarToggled(tempSideBarToggled);
  };

  return (
    <div className="container-fluid g-0">
      <div className="row g-0">
        <SideBar
          toggled={sideBarToggled}
          onToggle={handleToggleSidebar}
          sideBarLayout={getSidebarLayout()}
        />
        <div className="col g-0 p-0">
          <ResponsiveTopMenu onToggleSidebar={handleToggleSidebar} />
          <div className="row g-0 p-0 h-100">
            <div className="col-sm-12 info-panel-styling">
              <h1>Notifications</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
