import React, { useState } from "react";
import SideBar from "./sideBar";
import { getSidebarLayout } from "../services/menuDataService";
import ResponsiveTopMenu from "./responsiveTopMenu";
import SettingsMenuPanel from "./settingsMenuPanel";
import { getSettingsMenuLayout } from "../services/menuDataService";

const SettingsSecurity = () => {
  const [sideBarToggled, setSideBarToggled] = useState(false);

  const handleToggleSidebar = () => {
    const tempSideBarToggled = !sideBarToggled;
    setSideBarToggled(tempSideBarToggled);
  };
  let settingsMenuLayout = getSettingsMenuLayout();
  return (
    <div className="container-fluid g-0">
      <div className="row g-0">
        <SideBar
          toggled={sideBarToggled}
          onToggle={handleToggleSidebar}
          sideBarLayout={getSidebarLayout()}
        />
        <div className="col g-0 p-0">
          <ResponsiveTopMenu onToggleSidebar={handleToggleSidebar} />
          <div className="row g-0 p-0 h-100">
            <div className="col-sm-3 info-panel-styling">
              <SettingsMenuPanel
                settingsMenuLayout={settingsMenuLayout}
                selected="Security"
              />
            </div>
            <div className="col-sm-9 info-panel-styling p-3">Security</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsSecurity;
