import httpService from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

const apiEndpoint = config.apiUrl + "/templates";

let templates = [
  {
    id: 1,
    templateId: "use_this_as_a_test",
    name: "Test 1",
    message: "This is a Test",
    topics: "Topic 1, Topic2",
    dateadded: "2022-07-04 12:05Z",
  },
];
export function addTemplate(template) {
  return httpService.post(apiEndpoint, template);
}

export function removeTemplate(id) {
  return httpService.delete(apiEndpoint + "/" + id);
}

export function getAllTemplates() {
  return httpService.get(apiEndpoint);
  // return templates;
}

export function saveEdits(template) {
  return httpService.put(apiEndpoint, template);
}
