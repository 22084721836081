import React from "react";
import { Navigate } from "react-router-dom";
import { checkAccess } from "../services/userService";
import config from "../config.json";

const PrivateRoute = ({ children }) => {
  const access = checkAccess();
  const { urlPrefix } = config;
  return access ? children : <Navigate to={urlPrefix + "/login"} />;
};

export default PrivateRoute;
