import React from "react";
import Modal from "react-bootstrap/Modal";

const InformationModal = ({
  title,
  body,
  showModal,
  onHide,
  onOkButton,
  okButtonText,
}) => {
  return (
    <Modal show={showModal} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary fw-bold font-color-white rounded-pill"
          onClick={onOkButton}
        >
          {okButtonText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default InformationModal;
