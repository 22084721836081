import { FaGem, FaHeart, FaRobot, FaBars } from "react-icons/fa";
import {
  IoLogOutOutline,
  IoSettingsSharp,
  IoDocumentTextSharp,
} from "react-icons/io5";
import { TbMessages, TbMessage, TbChecks, TbTemplate } from "react-icons/tb";
import { RiScissorsFill } from "react-icons/ri";
import { DiGoogleAnalytics } from "react-icons/di";
import { AiFillDashboard } from "react-icons/ai";
import { MdLocationPin, MdStars } from "react-icons/md";
import { RiStarSmileFill } from "react-icons/ri";
import { IconContext } from "react-icons";
import { MdNotificationsActive } from "react-icons/md";
import { FaUserCog, FaKey, FaShoppingCart, FaBuffer } from "react-icons/fa";
import { MdAccountBalance } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";
import { BsFillChatTextFill } from "react-icons/bs";
import { AiFillPicture } from "react-icons/ai";

import config from "../config.json";

const { urlPrefix } = config;

const sideBarLayout = {
  header: {
    attributes: {
      title: "Messages",
      smallTitle: "M",
      logoIcon: {
        url: "https://reviewphoenix.com/Atlas/images/iconmix.png",
        size: {
          width: "33",
          height: "42",
        },
      },
      logo: {
        url: "https://reviewphoenix.com/Atlas/images/logo3mix2.png",
        size: {
          width: "110",
          height: "42",
        },
      },
    },
  },
  body: {
    menuItems: [
      {
        icon: <BsFillChatTextFill />,
        name: "My Messages",
        link: urlPrefix + "/chatapp",
      },
      {
        icon: <TbTemplate />,
        name: "Templates",
        link: urlPrefix + "/templates",
      },
      {
        icon: <IoDocumentTextSharp />,
        name: "Files",
        link: urlPrefix + "/files",
      },
      {
        icon: <FaShoppingCart />,
        name: "Product Catalog",
        link: urlPrefix + "/products",
      },
      {
        icon: <MdLocationPin />,
        name: "Store Locations",
        link: urlPrefix + "/locations",
      },
      {
        icon: <RiStarSmileFill />,
        name: "Reviews",
        // link: urlPrefix + "/locations",
      },

      // {
      //   icon: <FaRobot />,
      //   name: "Automation",
      //   link: "",
      // },
      {
        icon: <DiGoogleAnalytics />,
        name: "Analytics",
        link: urlPrefix + "/analytics",
      },
    ],
  },
  footer: {
    menuItems: [
      {
        icon: <MdNotificationsActive />,
        name: "Notifications",
        link: urlPrefix + "/notifications",
      },
      {
        icon: <IoSettingsSharp />,
        name: "Settings",
        link: urlPrefix + "/settings",
      },
      {
        icon: <FaBuffer />,
        name: "Account",
        // subMenuItems: [{ icon: "", name: "", link: "" }],
        // link: urlPrefix + "/notifications",
      },
      {
        icon: <IoLogOutOutline />,
        name: "Logout",
        link: urlPrefix + "/logout",
      },
    ],
  },
};

// menuItems: [
//   {
//     icon: "",
//     name: "",
//     link: "",
//     subMenuItems: [{ icon: "", name: "", link: "" }],
//   },
// ],

const settingsMenuData = [
  {
    iconElement: <FaUserCog />,
    elementTitle: "Users",
    elementDescription: "View, add, delete or update users",
    elementLink: urlPrefix + "/settings/users",
  },
  {
    iconElement: <MdAccountBalance />,
    elementTitle: "Account",
    elementDescription: "Manage account and links to external accounts",
    elementLink: urlPrefix + "/settings/account",
  },
  {
    iconElement: <IoIosNotifications />,
    elementTitle: "Notifications",
    elementDescription: "Manage notification settings",
    elementLink: urlPrefix + "/settings/notifications",
  },
  {
    iconElement: <FaKey />,
    elementTitle: "Security",
    elementDescription: "view and change your password and settings",
    elementLink: urlPrefix + "/settings/security",
  },
  {
    iconElement: <AiFillPicture />,
    elementTitle: "Appearance",
    elementDescription: "Change UI appearence, enable darkmode etc.",
    elementLink: urlPrefix + "/settings/appearance",
  },
];

export function getSidebarLayout(name = "default") {
  return sideBarLayout;
}

export function getSettingsMenuLayout(name = "default") {
  return settingsMenuData;
}
