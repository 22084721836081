import React, { useState, useEffect } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import SideBar from "./sideBar";
import SlidingPane from "react-sliding-pane";
import ContentLoader, { Facebook } from "react-content-loader";
import Modal from "react-bootstrap/Modal";
import { getSidebarLayout } from "../services/menuDataService";
import ResponsiveTopMenu from "./responsiveTopMenu";
import { FaPlus } from "react-icons/fa";
import { IconContext } from "react-icons";
import SearchBox from "./common/searchBox";
import TemplatesTable from "./templatesTable";
import { getAllTemplates, removeTemplate } from "../services/templatesService";
import RegisterTemplateForm from "./registerTemplateForm";
import ConfirmationModal from "./common/confirmationModal";
import EditTemplateForm from "./editTemplateForm";

let allData = [];
const Templates = () => {
  const MyLoader = (props) => (
    <ContentLoader
      speed={1}
      width={100}
      height={30}
      viewBox="0 0 100 30"
      backgroundColor="#f3f3f3"
      foregroundColor="#d9d9d9"
      {...props}
    >
      <rect x="11" y="5" rx="3" ry="3" width="77" height="5" />
      <rect x="12" y="23" rx="3" ry="3" width="52" height="6" />
    </ContentLoader>
  );

  const [sideBarToggled, setSideBarToggled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [state, setState] = useState({
    isRegisterTemplatePaneOpen: false,
    isEditTemplatePaneOpen: false,
  });
  const [sortColumn, setSortColumn] = useState({ path: "", order: "" });
  const [data, setData] = useState([
    {
      id: 10220303030330,
      template_id: MyLoader(),
      name: MyLoader(),
      message: MyLoader(),
      topics: MyLoader(),
      created_at_utc: MyLoader(),
    },
  ]);

  useEffect(() => {
    const getTempates = async () => {
      try {
        console.log("Wassup");

        const { data: dataList } = await getAllTemplates();
        // const dataList = await getAllTemplates();

        console.log("DataList", dataList);
        allData = dataList;
        setData(dataList);
        // return users;
      } catch (ex) {
        console.log(ex);
      }
    };
    getTempates();
  }, []);

  const getPagedData = (searchQuery, sortColumn) => {
    // const {
    //   pageSize,
    //   currentPage,
    //   sortColumn,
    //   users: allMovies,
    //   searchQuery,
    //   selectedGenre,
    // } = this.state;
    // console.log("searchQuery: ", searchQuery);
    let filtered = allData;
    if (searchQuery) {
      filtered = allData.filter((m) => {
        // m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
        const searchRegex = RegExp(searchQuery.toLowerCase(), "i");
        console.log(searchRegex);
        return (
          m.name.toLowerCase().match(searchRegex) ||
          m.message.toLowerCase().match(searchRegex)
        );
      });
    }

    const { length: filteredCount } = filtered;

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    // const movies = paginate(sorted, currentPage, pageSize);

    return { totalCount: filteredCount, data: sorted };
  };

  const handleSearch = (query) => {
    // console.log("Search");
    setSearchQuery(query);
    console.log("query: ", query);
    console.log("SearchQ: ", searchQuery);
    const pagedData = getPagedData(query, sortColumn);
    setData(pagedData.data);
  };

  const handleToggleSidebar = () => {
    const tempSideBarToggled = !sideBarToggled;
    setSideBarToggled(tempSideBarToggled);
  };

  const handleSort = (sortColumn) => {
    // console.log("Sort");
    setSortColumn(sortColumn);
    const pagedData = getPagedData(searchQuery, sortColumn);
    setData(pagedData.data);
  };

  const closeSlidingPanel = (paneAttrName) => {
    // setState({ isRegisterUserPaneOpen: false });
    setState({ [paneAttrName]: false });
  };

  const [showModal, setShowModal] = useState(false);
  const [templateIdToRemove, setTemplateIdToRemove] = useState();
  const [templateToEdit, setTemplateToEdit] = useState();

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleRemoveTemplate = (template_id) => {
    setTemplateIdToRemove(template_id);
    console.log("works");
    handleShowModal();
  };

  const handleRemoveTemplatewFromDB = async () => {
    const template_id = templateIdToRemove;
    handleCloseModal();
    try {
      const { data: result } = await removeTemplate(template_id);
      if (result.numUsersAffected >= 1) {
        console.log(result);
        let datalist = [...data];
        datalist = datalist.filter((item) => {
          return item.id !== template_id;
        });
        setData(datalist);
        // toast.info(`User ${email} has been removed.`, { autoClose: 2000 });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        toast.error("Could not delete: Template does not exist", {
          autoClose: 2000,
        });
    }
  };

  const handleAddDataToDataList = (newData) => {
    const topics = { ...newData.topics };
    delete newData.topics;
    newData.topics = JSON.stringify(topics);
    const dataList = [...data, newData];
    setData(dataList);
    allData.push(dataList);
  };

  const openAndPopulateEditTemplatePane = (template) => {
    console.log("Template to Edit: ", template);
    setTemplateToEdit(template);
    setState({ isEditTemplatePaneOpen: true });
  };

  const handleUpdateEditedDataToDataList = (editedDataItem) => {
    let datalist = [...data];
    datalist[
      _.findIndex(datalist, (item) => {
        return item.id === editedDataItem.id;
      })
    ] = editedDataItem;
    setData(datalist);
  };

  return (
    <div className="container-fluid g-0">
      <ConfirmationModal
        title="Confirm Delete Template"
        message="Are you sure you want to delete this template?"
        showModal={showModal}
        onHide={handleCloseModal}
        onCancelButton={handleCloseModal}
        onProceedButton={handleRemoveTemplatewFromDB}
        cancelButtonText="Cancel"
        proceedButtonText="Delete"
      />
      {/* <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this template?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary fw-bold rounded-pill"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="btn btn-danger fw-bold font-color-white rounded-pill"
            onClick={handleRemoveTemplatewFromDB}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal> */}

      <div className="row g-0">
        <SideBar
          toggled={sideBarToggled}
          onToggle={handleToggleSidebar}
          sideBarLayout={getSidebarLayout()}
        />

        <SlidingPane
          className="bg-light"
          overlayClassName="some-custom-overlay-class"
          isOpen={state.isRegisterTemplatePaneOpen}
          title="Add New Template"
          // title={slidingPaneTitle}
          subtitle="To add a new template, fill out the form and submit"
          width="70%"
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            setState({ isRegisterTemplatePaneOpen: false });
          }}
        >
          <div>
            <RegisterTemplateForm
              closeSlidingPanel={() =>
                closeSlidingPanel("isRegisterTemplatePaneOpen")
              }
              doaddNewDataToDataList={handleAddDataToDataList}
              // doaddNewUsertoUserList={handleaddNewUsertoUserList}
              // onCancelRegister={handleCancelRegister}
            />
          </div>
          <br />
        </SlidingPane>
        <SlidingPane
          className="bg-light"
          overlayClassName="some-custom-overlay-class"
          isOpen={state.isEditTemplatePaneOpen}
          title="Edit Template"
          // title={slidingPaneTitle}
          subtitle="Edit template information and save."
          width="70%"
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            setState({ isEditTemplatePaneOpen: false });
          }}
        >
          <div>
            <EditTemplateForm
              closeSlidingPanel={() =>
                closeSlidingPanel("isEditTemplatePaneOpen")
              }
              doUpdateEditedDataToDataList={handleUpdateEditedDataToDataList}
              data={templateToEdit}
              // onCancelRegister={handleCancelRegister}
            />
          </div>
          <br />
        </SlidingPane>

        <div className="col g-0 p-0">
          <ResponsiveTopMenu onToggleSidebar={handleToggleSidebar} />
          <div className="row g-0 p-0 h-100">
            <div className="col-sm-12 info-panel-styling">
              <div className="d-flex flex-column p-3">
                <h3>Templates</h3>
                <div className="d-flex flex-column border-bottom pb-3">
                  Create standard responses to be used in messages.
                </div>
              </div>
              <div className="d-flex flex-column px-3 py-2">
                <div className="d-flex flex-column flex-grow-1 bg-white  rounded-5 p-4 shadow">
                  <div className="d-flex flex-row align-content-center">
                    {/* <div className="flex-grow-1 align-content-center">
                    <h4>User Management</h4>
                  </div> */}
                    <div className="d-flex mx-3 align-content-center ">
                      <SearchBox
                        additionalClasses="rounded-pill"
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </div>
                    <div className="font-color-white">
                      <button
                        type="button"
                        className="btn btn-primary align-content-center rounded-pill mx-3 p-2"
                        onClick={() =>
                          setState({ isRegisterTemplatePaneOpen: true })
                        }
                      >
                        <IconContext.Provider
                          value={{
                            className: "shared-class font-color-white",
                            size: 15,
                          }}
                        >
                          <FaPlus />
                        </IconContext.Provider>
                        <span className="fw-bold font-color-white mx-2">
                          Add Template
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="mt-4">
                    <TemplatesTable
                      sortColumn={sortColumn}
                      onSort={handleSort}
                      data={data}
                      onRemoveTemplate={handleRemoveTemplate}
                      onClickOpenEditTemplatePane={
                        openAndPopulateEditTemplatePane
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Templates;
