import httpService from "./httpService";
import config from "../config.json";

const apiEndpoint = config.apiUrl + "/auth";
const tokenKey = "token";
httpService.setJwt(getJwt());

export function login(email, password) {
  return httpService.post(apiEndpoint, { email, password });
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function logout() {
  localStorage.clear();
}

// export function setupAppLoginVariables(userInfo) {
//   localStorage.setItem("token", userInfo.token);
//   localStorage.setItem("name", userInfo.user.first_name);
// }

export default {
  login,
  // setupAppLoginVariables,
  getJwt,
  logout,
};
