import React, { Component } from "react";
import Form from "./common/form";
import Joi from "joi-browser";
import * as UserService from "../services/userService";

class EditUserForm extends Form {
  state = {
    data: {
      id: 0,
      email: "",
      password: "",
      firstname: "",
      lastname: "",
      role: "",
      phonenumber: "",
    },
    errors: {},
  };

  componentDidMount() {
    const { id, email, firstname, lastname, role, phonenumber } =
      this.props.user;
    const data = {
      id: id,
      email: email,
      password: "",
      firstname: firstname,
      lastname: lastname,
      role: role,
      phonenumber: phonenumber,
    };
    this.setState({ data });
  }

  schema = {
    email: Joi.string().email().trim().required().label("e-mail"),
    password: Joi
      // .required()
      // .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{5,})/)
      .label("Password"),
    // .options({
    //   language: {
    //     string: {
    //       regex: {
    //         base: "must be atleast 5 characters long with numbers and capital, small and  special characters",
    //       },
    //     },
    //   },
    // }),
    firstname: Joi.string().trim().required().label("First Name"),
    lastname: Joi.string().trim().required().label("Last Name"),
    phonenumber: Joi.string().trim().max(16).label("Phone Number"),
    role: Joi.string().required().label("Role"),
    id: Joi.number().required().label("id"),
  };
  // username = React.createRef();

  // componentDidMount() {
  //   this.username.current.focus();
  // }

  doSubmit = async (e) => {
    try {
      let data = { ...this.state.data };
      data.email = data.email.toLowerCase();

      const result = await UserService.saveEdits(data);
      // localStorage.setItem("token", result.headers["x-auth-token"]);
      // localStorage.setItem("token", result);
      // window.location = "/";
      this.setState({ data });
      data.status = "Active";
      this.props.closeSlidingPanel();
      this.props.doaddNewUsertoUserList(data);
      console.log(result);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        // toast.error("User is already registered");
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };
  render() {
    const { data, errors } = this.state;
    const accounts = [
      { _id: "1", name: "ELARABY Group" },
      { _id: "2", name: "B.TECH" },
    ];
    const roles = [
      { _id: "1", name: "Admin" },
      { _id: "2", name: "User" },
    ];
    const { closeSlidingPanel } = this.props;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("email", "e-mail")}
          {this.renderInput("password", "Password", "password")}
          {this.renderInput("firstname", "First Name")}
          {this.renderInput("lastname", "Last Name")}
          {this.renderInput("phonenumber", "Phone Number")}
          {/* {this.renderSelect("account", "Account", accounts)} */}
          {this.renderSelect("role", "Role", roles)}
          {this.renderButton("Save", "btn-primary rounded-pill fw-bold")}

          {/* {this.renderButton("Cancel", "btn-danger rounded-pill")} */}
          <button
            type="button"
            className="btn btn-outline-danger rounded-pill hover-color-white fw-bold  ms-3"
            onClick={closeSlidingPanel}
          >
            Cancel
          </button>
        </form>
      </div>
    );
  }
}

export default EditUserForm;
