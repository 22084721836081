import React from "react";
import moment from "moment";
import Table from "./common/table";
import _ from "lodash";
import { BiEdit } from "react-icons/bi";
import { HiOutlineKey } from "react-icons/hi";
import { FiUserX } from "react-icons/fi";
import { MdRemoveCircleOutline } from "react-icons/md";
import { TbShieldCheck } from "react-icons/tb";
import { RiDeleteBin2Line } from "react-icons/ri";
import { GrValidate } from "react-icons/gr";
import { IconContext } from "react-icons";
import { removeUser } from "../services/userService";

const TemplatesTable = ({
  sortColumn,
  onRemoveTemplate,
  //   onSuspendUser,
  //   onUnSuspendUser,
  data,
  onSort,
  // doaddNewUsertoUserList,
  onClickOpenEditTemplatePane,
}) => {
  const convertTextToTimeText = (timeText) => {
    //add a regex to check for time formatted text
    if (typeof timeText === "string") {
      let pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}/i;
      let result = timeText.match(pattern);
      // if (timeText !== "Loading...")
      if (result !== null)
        return moment(_.trim(timeText)).local().format("YYYY-MM-DD");
      else return timeText;
    } else {
      return timeText;
    }
    // return moment("2022-02-01T10:20Z").local().format("YYYY-MM-DD");
    // return moment("2022-07-10T10:30:10Z").local().format("YYYY-MM-DD");
  };

  const generateTopicsList = (topics) => {
    // return topics;
    // return JSON.parse(topics).join();

    console.log("TOPICS TYPE:", topics);
    if (typeof topics === "string") {
      // if (topics !== "Loading...") {
      try {
        const { topics: topicsArray } = JSON.parse(topics);
        // const result = topicsArray.join();

        return (
          <div className="d-flex flex-row flex-wrap max-width-400px">
            {topicsArray.map((topic) => (
              <div key={topic} className="bg-color-grey m-1 px-1 rounded">
                {topic}
              </div>
            ))}
          </div>
        );
      } catch (ex) {
        console.log(ex);
        return topics;
      }
    } else if (typeof topics === "object" && topics.topics) {
      const topicsArray = topics.topics;
      return (
        <div className="d-flex flex-row flex-wrap max-width-400px">
          {topicsArray.map((topic) => (
            <div key={topic} className="bg-color-grey m-1 px-1 rounded">
              {topic}
            </div>
          ))}
        </div>
      );
    } else {
      return topics;
    }
  };
  let columns = [
    {
      path: "name",
      label: "NAME",
    },
    { path: "message", label: "MESSAGE" },
    { path: "topics", label: "TOPICS", processData: generateTopicsList },
    { path: "template_id", label: "ID" },
    {
      path: "created_at_utc",
      label: "DATE ADDED",
      processData: convertTextToTimeText,
    },
    {
      key: "actions",
      label: "ACTIONS",
      content: (item) => (
        <IconContext.Provider
          value={{
            className: "shared-class",
            size: 15,
            //  color: "#465f78",
          }}
        >
          <span
            className="mx-1 px-1 pb-1 hover-grey rounded clickable font-color-primary"
            onClick={() => onClickOpenEditTemplatePane(item)}
          >
            <BiEdit />
          </span>
          {/* <span
            // className="mx-2 clickable font-color-danger"
            className={
                template.status === "Active"
                ? "mx-1 px-1 pb-1 hover-grey rounded clickable font-color-danger"
                : "mx-1 px-1 pb-1 hover-grey rounded clickable font-color-green"
            }
            onClick={() =>
              user.status === "Active"
                ? onSuspendUser(template.email)
                : onUnSuspendUser(template.email)
            }
          >
            {user.status === "Active" ? (
              <MdRemoveCircleOutline />
            ) : (
              <TbShieldCheck />
            )}
          </span> */}
          <span
            className="mx-1 px-1 pb-1 hover-grey rounded clickable font-color-danger"
            onClick={() => onRemoveTemplate(item.id)}
          >
            <RiDeleteBin2Line />
          </span>
        </IconContext.Provider>

        // <button
        //   onClick={() => onDelete(movie)}
        //   className="btn btn-danger btn-sm"
        // >
        //   Delete
        // </button>
      ),
    },

    // {
    //   key: "delete",
    //   content: (movie) => (
    //     <button
    //       onClick={() => this.props.onDelete(movie)}
    //       className="btn btn-danger btn-sm"
    //     >
    //       Delete
    //     </button>
    //   ),
    // },
  ];

  //   const users2 = [
  //     {
  //       id: 1,
  //       name: "Bill Walters",
  //       email: "bill@gmail.com",
  //       role: "Admin",
  //     },
  //     {
  //       id: 2,
  //       name: "Bill2 Walters",
  //       email: "bill2@gmail.com",
  //       role: "Admin",
  //     },
  //   ];
  //   const users3 = [];

  return (
    <Table
      columns={columns}
      onSort={onSort}
      sortColumn={sortColumn}
      data={data}
    />
  );
};

export default TemplatesTable;
