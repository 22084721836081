import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { login } from "../services/authService";
import { setupAppLoginVariables } from "../services/userService";
import { toast } from "react-toastify";
import { withParams, withNavigation } from "../services/hocs";
import config from "../config.json";

const { urlPrefix } = config;
class LoginForm extends Form {
  state = {
    data: { email: "", password: "" },
    errors: {},
  };

  schema = {
    email: Joi.string().required().label("E-mail"),
    password: Joi.string().required().label("Password"),
  };
  // username = React.createRef();

  // componentDidMount() {
  //   this.username.current.focus();
  // }

  doSubmit = async (e) => {
    console.log("Submitted");
    const { email, password } = this.state.data;
    // localStorage.setItem("username", email);
    // window.location = "/chatapp/chatapp";

    // login(email, password)
    //   .then(({ data: userInfo }) => {
    //     setupAppLoginVariables(userInfo);
    //     // localStorage.setItem("token", jwt);
    //     window.location = "/chatapp/chatapp";
    //   })
    //   .catch((ex) => {
    //     console.log("WTF");
    //     console.log(ex);
    //     if (ex.response && ex.response.status === 400) {
    //       // toast.error(ex.response.data);
    //       const errors = { ...this.state.errors };
    //       errors.email = ex.response.data;
    //       this.setState({ errors });
    //     }
    //   });

    try {
      console.log("email: ", email, "password: ", password);
      const { data: userInfo } = await login(email, password);
      setupAppLoginVariables(userInfo);
      // localStorage.setItem("token", jwt);
      window.location = urlPrefix + "/chatapp";
    } catch (ex) {
      // console.log(ex);
      if (ex.response && ex.response.status === 400) {
        // toast.error(ex.response.data);
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    const { data, errors } = this.state;
    return (
      <div className="container-fluid g-0">
        <div className="row bg-color-light-blue login-background-image g-0 p-0 h-100 vh-100">
          <div className="col-sm-12 d-flex justify-content-center align-items-center">
            <div className="bg-white shadow-lg p-4" style={{ width: "400px" }}>
              <div className="d-flex justify-content-center align-items-center my-4">
                <img
                  src="https://reviewphoenix.com/Atlas/images/logo3mix2.png"
                  style={{ width: "220px", height: "84px" }}
                />
              </div>
              <form onSubmit={this.handleSubmit}>
                {this.renderInput("email", "E-mail")}
                {this.renderInput("password", "Password", "password")}
                {this.renderButton(
                  "Login",
                  "fw-bold btn-primary px-4 rounded-pill"
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNavigation(withParams(LoginForm));
