import React from "react";
import Table from "./common/table";
import { BiEdit } from "react-icons/bi";
import { HiOutlineKey } from "react-icons/hi";
import { FiUserX } from "react-icons/fi";
import { MdRemoveCircleOutline } from "react-icons/md";
import { TbShieldCheck } from "react-icons/tb";

import { GrValidate } from "react-icons/gr";
import { IconContext } from "react-icons";
import { removeUser } from "../services/userService";

const UsersTable = ({
  sortColumn,
  onRemoveUser,
  onSuspendUser,
  onUnSuspendUser,
  users,
  onSort,
  // doaddNewUsertoUserList,
  onClickOpenEditUserPane,
}) => {
  let columns = [
    {
      path: "name",
      label: "NAME",
    },
    { path: "email", label: "EMAIL" },
    { path: "role", label: "ROLE" },
    { path: "status", label: "STATUS" },
    {
      key: "actions",
      label: "ACTIONS",
      content: (user) => (
        <IconContext.Provider
          value={{
            className: "shared-class",
            size: 15,
            //  color: "#465f78",
          }}
        >
          <span
            className="mx-1 px-1 pb-1 hover-grey rounded clickable font-color-primary"
            onClick={() => onClickOpenEditUserPane(user)}
          >
            <BiEdit />
          </span>
          <span
            // className="mx-2 clickable font-color-danger"
            className={
              user.status === "Active"
                ? "mx-1 px-1 pb-1 hover-grey rounded clickable font-color-danger"
                : "mx-1 px-1 pb-1 hover-grey rounded clickable font-color-green"
            }
            onClick={() =>
              user.status === "Active"
                ? onSuspendUser(user.email)
                : onUnSuspendUser(user.email)
            }
          >
            {user.status === "Active" ? (
              <MdRemoveCircleOutline />
            ) : (
              <TbShieldCheck />
            )}
          </span>
          <span
            className="mx-1 px-1 pb-1 hover-grey rounded clickable font-color-danger"
            onClick={() => onRemoveUser(user.email)}
          >
            <FiUserX />
          </span>
        </IconContext.Provider>

        // <button
        //   onClick={() => onDelete(movie)}
        //   className="btn btn-danger btn-sm"
        // >
        //   Delete
        // </button>
      ),
    },

    // {
    //   key: "delete",
    //   content: (movie) => (
    //     <button
    //       onClick={() => this.props.onDelete(movie)}
    //       className="btn btn-danger btn-sm"
    //     >
    //       Delete
    //     </button>
    //   ),
    // },
  ];

  //   const users2 = [
  //     {
  //       id: 1,
  //       name: "Bill Walters",
  //       email: "bill@gmail.com",
  //       role: "Admin",
  //     },
  //     {
  //       id: 2,
  //       name: "Bill2 Walters",
  //       email: "bill2@gmail.com",
  //       role: "Admin",
  //     },
  //   ];
  //   const users3 = [];

  return (
    <Table
      columns={columns}
      onSort={onSort}
      sortColumn={sortColumn}
      data={users}
    />
  );
};

export default UsersTable;
