import React, { useEffect, useState } from "react";
import moment from "moment";
import { BsCheck2, BsCheck2All } from "react-icons/bs";
import { IoMdTime } from "react-icons/io";
import { IconContext } from "react-icons";
import "moment-timezone";

const Message = ({
  _id,
  message,
  northSouth,
  name,
  dateTimeText,
  deliveryStatus,
  timeStamp,
}) => {
  const [timeText, setTimeText] = useState();
  const [deliveryStatusIcon, setDeliveryStatusIcon] = useState();
  const [deliveryStatusColor, setDeliveryStatusColor] = useState();
  useEffect(() => {
    const currentTime = moment();
    const dateTime = moment(dateTimeText);
    // const dateTime = moment(timeStamp);
    let timeText = dateTime.local().format("h:mm a");
    const timediff = currentTime.diff(dateTime);
    if (timediff < 60 * 60 * 24 * 1000) {
      timeText = dateTime.local().format("h:mm a");
    } else if (timediff < 60 * 60 * 24 * 365 * 1000) {
      timeText = dateTime.local().format("DD-MMM h:mm a");
    } else timeText = dateTime.local().format("DD-MMM-YYYY h:mm a");
    setTimeText(timeText);
  }, [dateTimeText]);

  useEffect(() => {
    let deliveryStatusIcon;
    let deliveryStatusColor = "black";
    if (deliveryStatus === "SentToServer") deliveryStatusIcon = <BsCheck2 />;
    else if (deliveryStatus === "RecievedByUser") {
      deliveryStatusIcon = <BsCheck2All />;
      deliveryStatusColor = "green";
    } else if (deliveryStatus === "Sending") deliveryStatusIcon = <IoMdTime />;
    setDeliveryStatusIcon(deliveryStatusIcon);
    setDeliveryStatusColor(deliveryStatusColor);
  }, [deliveryStatus]);
  // if (timediff < 60 * 1000) {
  //   timeText = dateTime.format("s") + " secs";
  // } else if (timediff < 60 * 60 * 1000) {
  //   timeText = dateTime.format("m") + " mins";
  // } else if (timediff < 60 * 60 * 24 * 1000) {
  //   timeText = dateTime.format("h:mm a");
  // } else if (timediff < 60 * 60 * 24 * 365 * 1000) {
  //   timeText = dateTime.format("DD-MMM h:mm a");
  // } else timeText = dateTime.format("DD-MMM-YYYY h:mm a");

  // if (timediff > 60 * 60 * 24 * 1000) {
  //   if (timediff > 365 * 60 * 60 * 24 * 1000) {
  //     timeText = dateTime.format("DD-MMM-YYYY h:mm a");
  //   } else {
  //     timeText = dateTime.format("DD-MMM h:mm a");
  //   }
  //   // timeText = dateTime.format();
  // }

  // });
  return (
    <div
      className={
        northSouth === "south"
          ? "d-inline-flex m-2 flex-row"
          : "d-inline-flex m-2 flex-row-reverse"
      }
    >
      <div
        className={
          northSouth === "south"
            ? "msgbox-inner align-items-start"
            : "msgbox-inner align-items-end"
        }
      >
        <div
          className={
            northSouth === "south"
              ? "py-2 px-3 message-text-south-format"
              : "py-2 px-3 message-text-north-format"
          }
        >
          {northSouth === "south" && (
            <div className="msg-name-format">{name}</div>
          )}
          <div>{message}</div>
        </div>
        <div
          className={
            northSouth === "south"
              ? "msg-time-format fst-italic text-start"
              : "msg-time-format fst-italic text-end"
          }
        >
          {timeText}
          <span className="mx-1">
            {deliveryStatusIcon && northSouth === "north" && (
              <IconContext.Provider
                value={{
                  className: "shared-class",
                  size: 14,
                  color: deliveryStatusColor,
                }}
              >
                {deliveryStatusIcon}
              </IconContext.Provider>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Message;
