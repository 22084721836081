import httpService from "./httpService";
import config from "../config.json";
import { getJwt } from "./authService";

const apiEndpoint = config.apiUrl + "/users";

let users = [
  {
    id: 1,
    email: "mbadr@gmail.com",
    username: "mbadr@gmail.com",
    firstName: "Mohamed",
    lastName: "Badr",
    displayName: "Mo",
    accountId: 543543,
    authorization: {
      roles: ["admin", "editor"],
    },
  },
];

let userRoles = {
  admin: { settings: "all" },
  editor: { settings: "all" },
};
export function register(user) {
  return httpService.post(apiEndpoint, user);
}
export function checkAccess() {
  let access = false;
  try {
    if (getJwt()) access = true;
  } catch {
    access = false;
  }
  return access;
}

//this needs to be fixed
export function getCurrentUserInfo() {
  // const token = getJwt();
  // const { data: user } = httpService.get(apiEndpoint + "/me");
  // return { token, user };
  return httpService.get(apiEndpoint + "/me");
}

export function removeUser(email) {
  return httpService.delete(apiEndpoint + "/" + email);
}
export function suspendUser(email) {
  return httpService.put(apiEndpoint + "/suspend/" + email);
}
export function unSuspendUser(email) {
  return httpService.put(apiEndpoint + "/unsuspend/" + email);
}

export function getAllUsers() {
  return httpService.get(apiEndpoint);
}

export function saveEdits(user) {
  return httpService.put(apiEndpoint, user);
}

export function setupAppLoginVariables(userInfo) {
  const user = { name: userInfo.user.first_name, id: userInfo.user.id };
  // console.log("CHECK!!!", userInfo);
  localStorage.setItem("token", userInfo.token);
  localStorage.setItem("user", JSON.stringify(user));
}

export function getCurrentUserFromLocalStorage() {
  return JSON.parse(localStorage.getItem("user"));
}

export function getAssignToUserList() {
  return httpService.get(apiEndpoint + "/assignto/");
}
