import React from "react";

const NameBox = ({ name, imageUrl, size }) => {
  // if(!name) name="None Selected"
  let initials = "";
  if (name) {
    const fullName = name.split(" ");
    if (fullName.length > 1) {
      initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    } else {
      initials = fullName[0].charAt(0);
    }
  }
  const initialsUpper = initials.toUpperCase();
  let textStyling = "fw-bold m-0 ";
  let circleStyling = "fw-bold me-2 circle-with-initals";
  if (size === "small") {
    textStyling += "fs-7";
    circleStyling += "-s";
  }
  return (
    // <div className="d-inline-flex align-items-center p-1 m-1">
    <div className="d-inline-flex align-items-center">
      <div className={circleStyling}>{initialsUpper}</div>
      <p className={textStyling}>{name}</p>
    </div>
  );
};

export default NameBox;
