import React, { Component } from "react";
import Form from "./common/form";
import moment from "moment";
import Joi from "joi-browser";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as TemplatesService from "../services/templatesService";
import { TagsInput } from "react-tag-input-component";

class EditTemplateForm extends Form {
  state = {
    data: {
      id: 5,
      template_id: "",
      name: "",
      message: "",
      topics: { topics: [] },
    },
    errors: {},
  };

  constructor(props) {
    super(props);
    // const { id, template_id, name, message, topics } = this.props.user;
    // const data = { ...this.props.data };
    const { id, template_id, name, message, topics, created_at_utc } =
      this.props.data;
    const data = {
      id: id,
      template_id: template_id,
      name: name,
      message: message,
      topics: JSON.parse(topics),
    };
    console.log("test", data);
    this.state.data = { ...data };
    // this.setState({ data }, () => {
    //   console.log("test2", this.state.data);
    // });
  }

  // componentDidMount() {
  //   // const { id, template_id, name, message, topics } = this.props.user;
  //   // const data = { ...this.props.data };
  //   const { id, template_id, name, message, topics, created_at_utc } =
  //     this.props.data;
  //   const data = {
  //     id: id,
  //     template_id: template_id,
  //     name: name,
  //     message: message,
  //     topics: JSON.parse(topics),
  //     created_at_utc: created_at_utc,
  //   };
  //   console.log("test", data);
  //   this.setState({ data }, () => {
  //     console.log("test2", this.state.data);
  //   });
  // }

  schema = {
    name: Joi.string().trim().required().label("Name"),
    message: Joi.string().trim().required().label("Message"),
    template_id: Joi.string().trim().required().label("Template ID"),
    // topics: Joi.object().items(Joi.string()).label("Topics"),
    topics: Joi.object({
      topics: Joi.array().items(Joi.string()),
    }).label("Topics"),
    id: Joi.number(),
  };
  // username = React.createRef();

  // componentDidMount() {
  //   this.username.current.focus();
  // }

  doSubmit = async (e) => {
    try {
      let data = { ...this.state.data };

      const { data: result } = await TemplatesService.saveEdits(data);
      // console.log("Insert Result", result);
      // data.id = result.insertId;
      // data.created_at_utc = moment().utc().format("YYYY-MM-DD HH:mm:ss") + "Z";
      // this.setState({ data });
      // data.status = "active";
      this.props.closeSlidingPanel();
      this.props.doUpdateEditedDataToDataList(data);
      // console.log(result);
    } catch (ex) {
      console.log("Test2: ", ex);
      if (ex.response && ex.response.status === 400) {
        // toast.error("User is already registered");
        console.log("Test3");
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  setTopics = (topics) => {
    console.log("topics", topics);
    console.log("topics", this.state.data.topics.topics);
    const data = { ...this.state.data };
    data.topics.topics = topics;
    // this.setState({ data });
  };
  render() {
    const { data, errors } = this.state;
    const { closeSlidingPanel } = this.props;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("name", "Name")}
          {this.renderInput("message", "Message")}
          {this.renderInput("template_id", "Template ID")}
          {/* {this.renderTagsInput(
            "topics",
            "Topics",
            "press enter or comma to add new topic"
          )} */}
          <div className="form-group mb-3">
            <label htmlFor="topics">Topics</label>
            <TagsInput
              value={this.state.data.topics.topics}
              onChange={this.setTopics}
              name="fruits"
              placeHolder="press enter to add new topic"
            />
          </div>

          {/* <Autocomplete
            style={{ margin: "10px 0" }}
            multiple
            id="tags-outlined"
            options={data.topics}
            // defaultValue={[...data.topics]}

            freeSolo
            autoSelect
            onChange={(e) => {
              let datatemp = { ...this.state.data };
              datatemp.topics = [...data.topics, e.target.value];
              this.setState({ data: datatemp });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Topics"
                placeholder="Topics"
                value={data.topics}
              />
            )}
          /> */}
          {/* {this.renderSelect("role", "Role", roles)} */}
          {this.renderButton(
            "Submit Template",
            "btn-primary rounded-pill fw-bold"
          )}

          {/* {this.renderButton("Cancel", "btn-danger rounded-pill")} */}
          <button
            type="button"
            className="btn btn-outline-danger rounded-pill hover-color-white fw-bold ms-3"
            onClick={closeSlidingPanel}
          >
            Cancel
          </button>
        </form>
      </div>
    );
  }
}

export default EditTemplateForm;
