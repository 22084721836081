import React, { Component } from "react";
import Message from "./message";
import { getCurrentUserFromLocalStorage } from "../services/userService";

class ChatMessageArea extends Component {
  state = {};

  componentDidMount() {
    this.chatArea.scrollTop = this.chatArea.scrollHeight;
    this.props.onUpdateScrollMsgs();
  }

  componentDidUpdate() {
    if (this.props.updateScrollMsgs) {
      this.chatArea.scroll({
        top: this.chatArea.scrollHeight,
        behavior: "smooth",
      });
      this.props.onUpdateScrollMsgs();
    }
  }

  render() {
    const { chatMessages } = this.props;
    const currentUser = getCurrentUserFromLocalStorage();
    // console.log(currentUser);
    return (
      <div
        className="d-flex flex-grow-1 flex-column overflow-y-styling px-2"
        style={{ flex: 1 }}
        ref={(div) => {
          this.chatArea = div;
        }}
      >
        {chatMessages
          .sort((a, b) => {
            const timestampA = new Date(a.datetime).getTime();
            const timestampB = new Date(b.datetime).getTime();
            return timestampA <= timestampB ? -1 : 1;

            // return a.timeStamp <= b.timeStamp ? -1 : 1;
          })
          .map((msg) => (
            <Message
              key={msg.id}
              message={msg.message}
              name={msg.displayname}
              northSouth={currentUser.id == msg.senderId ? "north" : "south"}
              dateTimeText={msg.datetime}
              timeStamp={msg.timeStamp}
              deliveryStatus={msg.deliverystatus}
            />
          ))}
      </div>
    );
  }
}

export default ChatMessageArea;
