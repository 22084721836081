import React from "react";
import SettingsMenuPanelElement from "./settingsMenuPanelElement";
import Media from "react-media";
import Accordion from "react-bootstrap/Accordion";

const SettingsMenuPanel = ({ settingsMenuLayout, selected }) => {
  return (
    <Media
      queries={{
        small: "(max-width: 767px)",
        large: "(min-width: 768px)",
      }}
    >
      {(matches) => (
        <React.Fragment>
          {matches.small && (
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <span style={{ fontWeight: "800" }}>Settings</span>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex flex-column flex-grow-1 border-bottom border-1 overflow-y-styling">
                    {settingsMenuLayout.map((element) => (
                      <SettingsMenuPanelElement
                        key={element.elementTitle}
                        iconElement={element.iconElement}
                        elementTitle={element.elementTitle}
                        elementDescription={element.elementDescription}
                        elementLink={element.elementLink}
                        selected={selected === element.elementTitle && "true"}
                        // selected={true}
                      />
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
          {matches.large && (
            <React.Fragment>
              <div
                className="border-bottom border-1 p-2"
                style={{ height: "60px" }}
              >
                <h2>Settings</h2>
              </div>
              <div className="d-flex flex-column flex-grow-1 border-bottom border-1 overflow-y-styling">
                {settingsMenuLayout.map((element) => (
                  <SettingsMenuPanelElement
                    key={element.elementTitle}
                    iconElement={element.iconElement}
                    elementTitle={element.elementTitle}
                    elementDescription={element.elementDescription}
                    elementLink={element.elementLink}
                    selected={selected === element.elementTitle && "true"}
                    // selected={true}
                  />
                ))}
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Media>

    // <div className="d-flex flex-column flex-grow-1 border-bottom border-1 overflow-y-styling">
    //   {settingsMenuLayout.map((element) => (
    //     <SettingsMenuPanelElement
    //       key={element.elementTitle}
    //       iconElement={element.iconElement}
    //       elementTitle={element.elementTitle}
    //       elementDescription={element.elementDescription}
    //       elementLink={element.elementLink}
    //       selected={selected === element.elementTitle && "true"}
    //       // selected={true}
    //     />
    //   ))}
    // </div>
  );
};

export default SettingsMenuPanel;
